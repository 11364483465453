import {
  GET_CLOSED_SLOTS_REQUEST,
  GET_CLOSED_SLOTS_SUCCESS,
  GET_CLOSED_SLOTS_FAILURE
} from '../types/closedTypes'

const initialState = {
  closedSlots: [],
  loading: false,
  error: null
}

export const closedReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLOSED_SLOTS_REQUEST:
      return { ...state, loading: true }

    case GET_CLOSED_SLOTS_SUCCESS:
      return { ...state, loading: false, closedSlots: action.payload }

    case GET_CLOSED_SLOTS_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}

export default closedReducer
