import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAppointments,
  cancelAppointment,
  deleteAppointment
} from '../actions/appointmentActions'
import EmployeeAppointmentList from '../components/EmployeeAppointmentList'
import EmployeeAppointmentSettings from '../components/EmployeeAppointmentSettings'
import EmployeeAppointmentCalendar from '../components/EmployeeAppointmentCalendar'

const EmployeeDashboard = () => {
  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState('view')

  return (
    <div className="max-w-2xl mx-auto pt-4 pb-10 px-0 md:p-4 lg:min-h-[calc(100vh-200px)]">
      <h1 className="text-3xl font-bold text-center my-4">Çalışan Paneli</h1>

      <div className="flex justify-center space-x-4 mb-4 px-4 font-semibold">
        <button
          onClick={() => setActiveTab('view')}
          className={`py-2 px-4 rounded-md text-lg transition duration-200 ${
            activeTab === 'view'
              ? 'bg-blue-600 text-white'
              : 'bg-gray-200 text-gray-500'
          }`}
        >
          📜 Randevu Görüntüle
        </button>

        <button
          onClick={() => setActiveTab('settings')}
          className={`py-2 px-4 rounded-md text-lg transition duration-200 ${
            activeTab === 'settings'
              ? 'bg-yellow-600 text-white'
              : 'bg-gray-200 text-gray-500'
          }`}
        >
          ⚙️ Randevuya Kapalı Zamanlar
        </button>

        <button
          onClick={() => setActiveTab('create')}
          className={`py-2 px-4 rounded-md text-lg transition duration-200 ${
            activeTab === 'create'
              ? 'bg-green-600 text-white'
              : 'bg-gray-200 text-gray-500'
          }`}
        >
          📅 Randevu Oluştur
        </button>
      </div>

      <div className="bg-white p-0 md:p-6 rounded-3xl shadow-md">
        {activeTab === 'view' ? (
          <EmployeeAppointmentList />
        ) : activeTab === 'settings' ? (
          <EmployeeAppointmentSettings activeTab="settings" />
        ) : activeTab === 'create' ? (
          <EmployeeAppointmentCalendar />
        ) : null}
      </div>
    </div>
  )
}

export default EmployeeDashboard
