import {
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE,
  REMOVE_MESSAGE
} from '../types/serviceTypes'

const initialState = {
  services: [],
  loading: false,
  message: ''
}

export const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICES_REQUEST:
      return {
        ...state,
        loading: true,
        message: ''
      }

    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
        loading: false,
        message: ''
      }
    case GET_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload
      }
    case REMOVE_MESSAGE:
      return {
        ...state,
        message: ''
      }
    default:
      return state
  }
}

export default serviceReducer
