import {
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_SUCCESS,
  CREATE_APPOINTMENT_FAILURE,
  GET_APPOINTMENTS_REQUEST,
  GET_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENTS_FAILURE,
  UPDATE_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILURE,
  DELETE_APPOINTMENT_REQUEST,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_APPOINTMENT_FAILURE,
  GET_ALL_APPOINTMENTS_REQUEST,
  GET_ALL_APPOINTMENTS_SUCCESS,
  GET_ALL_APPOINTMENTS_FAILURE,
  SET_MESSAGE,
  REMOVE_MESSAGE
} from '../types/appointmentTypes'

const initialState = {
  appointments: [],
  loading: false,
  message: ''
}

export const appointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_APPOINTMENT_REQUEST:
    case GET_APPOINTMENTS_REQUEST:
    case UPDATE_APPOINTMENT_REQUEST:
    case DELETE_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        message: ''
      }

    case CREATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: [...state.appointments, action.payload.appointment],
        message: action.payload.message
      }

    case GET_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: action.payload
      }

    case UPDATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: state.appointments.map((appointment) =>
          appointment._id === action.payload.appointment._id
            ? action.payload.appointment
            : appointment
        ),
        message: action.payload.message
      }

    case DELETE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: state.appointments.filter(
          (appointment) => appointment._id !== action.payload
        ),
        message: action.payload.message
      }

    case CREATE_APPOINTMENT_FAILURE:
    case GET_APPOINTMENTS_FAILURE:
    case UPDATE_APPOINTMENT_FAILURE:
    case DELETE_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload
      }

    case GET_ALL_APPOINTMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        message: ''
      }

    case GET_ALL_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: action.payload
      }

    case GET_ALL_APPOINTMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload
      }

    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload
      }

    case REMOVE_MESSAGE:
      return {
        ...state,
        message: ''
      }

    default:
      return state
  }
}

export default appointmentReducer
