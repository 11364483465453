import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getServices } from '../actions/serviceActions'
import toast from 'react-hot-toast'
import { IoDiamond } from 'react-icons/io5'

const ServiceSelection = ({
  selectedEmployee,
  selectedService,
  setSelectedService,
  setActiveTab
}) => {
  const dispatch = useDispatch()
  const { services } = useSelector((state) => state.serviceReducer || {})

  useEffect(() => {
    dispatch(getServices())
  }, [dispatch])

  const handleServiceSelect = (service) => {
    setSelectedService(service)
  }

  const handleNext = () => {
    if (selectedService && selectedEmployee) {
      setActiveTab('calendar')
    } else {
      toast.error('Lütfen önce bir hizmet seçin.')
    }
  }

  const handleBack = () => {
    setActiveTab('employeeSelection')
  }

  const normalServices = services.filter((service) => !service?.vip)
  const vipServices = services.filter((service) => service?.vip)

  return (
    <div className="service-selection p-4 font-semibold">
      {selectedEmployee?.vip ? (
        <div className="flex items-center justify-between w-full mb-4 ml-1">
          {/* Geri butonu */}
          <button
            onClick={handleBack}
            className="flex bg-orange-400 text-white px-2 md:px-4 py-2 rounded-lg hover:bg-orange-500"
          >
            <svg
              className="w-6 h-6 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 12h14M5 12l4-4m-4 4 4 4"
              />
            </svg>
          </button>

          {/* VIP Hizmet Seçimi başlığı ve simgesi */}
          <div className="flex items-center">
            <IoDiamond className="inline-block text-4xl mr-2 md:mr-4" />
            <h2 className="text-2xl font-redrose text-center text-gray-900">
              VIP Hizmet Seçimi
            </h2>
          </div>

          {/* İleri butonu */}
          <button
            onClick={handleNext}
            className="flex bg-indigo-400 text-white px-2 md:px-4 py-2 rounded-lg hover:bg-indigo-500 mr-2"
          >
            <svg
              className="w-6 h-6 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </button>
        </div>
      ) : (
        <div className="flex items-center justify-between w-full mb-6">
          {/* Geri butonu */}
          <button
            onClick={handleBack}
            className="flex bg-orange-400 text-white px-3 md:px-6 py-2 rounded-lg hover:bg-orange-500 font-semibold"
          >
            <svg
              className="mr-1 w-6 h-6 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 12h14M5 12l4-4m-4 4 4 4"
              />
            </svg>
            Geri
          </button>

          {/* Hizmet Seçimi başlığı */}
          <h2 className="text-2xl font-bold text-center text-blue-500">
            Hizmet Seçimi
          </h2>

          {/* İleri butonu */}
          <button
            onClick={handleNext}
            className="flex bg-indigo-400 text-white px-3 md:px-6 py-2 rounded-lg hover:bg-indigo-500"
          >
            İleri
            <svg
              className="ml-1 w-6 h-6 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </button>
        </div>
      )}

      {/* Eğer selectedEmployee null ise hata mesajı */}
      {!selectedEmployee ? (
        <p className="text-red-500 text-center font-semibold">
          "Hizmet Seçimi" yapabilmek için "Çalışan Seçimi" gereklidir,{' '}
          <span className="text-orange-400">"Geri"</span> butonuna tıklayınız.
        </p>
      ) : (
        <div className="services grid grid-cols-2 gap-4">
          {selectedEmployee?.vip
            ? vipServices.map((service) => (
                <div
                  key={service._id}
                  onClick={() => handleServiceSelect(service)}
                  className={`p-4 border-4 rounded-xl cursor-pointer transition-colors duration-200 ease-in-out ${
                    selectedService?._id === service._id
                      ? 'bg-gray-950 text-gray-50 border-4 border-orange-500'
                      : 'bg-gray-700 hover:bg-gray-950 text-gray-50 border-gray-50'
                  }`}
                >
                  <div className="flex flex-col md:flex-row font-redrose items-center md:space-x-1">
                    <span>{service.name}</span>
                    <span className="whitespace-nowrap">
                      <span className="text-blue-300 text-lg">•</span>{' '}
                      {service.price} TL
                    </span>
                  </div>
                </div>
              ))
            : normalServices.map((service) => (
                <div
                  key={service._id}
                  onClick={() => handleServiceSelect(service)}
                  className={`p-4 border-2 border-blue-100 rounded-xl cursor-pointer transition-colors duration-200 ease-in-out ${
                    selectedService?._id === service._id
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 hover:bg-blue-100'
                  }`}
                >
                  <p>
                    {service.name} - {service.price} TL
                  </p>
                </div>
              ))}
        </div>
      )}

      <div className="flex justify-between mt-6 font-semibold">
        <button
          onClick={handleBack}
          className="flex bg-orange-400 text-white px-6 py-2 rounded-lg hover:bg-orange-500"
        >
          <svg
            className="mr-1 w-6 h-6 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 12h14M5 12l4-4m-4 4 4 4"
            />
          </svg>
          Geri
        </button>

        <button
          onClick={handleNext}
          className="flex bg-indigo-400 text-white px-6 py-2 rounded-lg hover:bg-indigo-500"
        >
          İleri
          <svg
            className="ml-1 w-6 h-6 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 12H5m14 0-4 4m4-4-4-4"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default ServiceSelection
