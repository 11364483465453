import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <section className="bg-white">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-zinc-600">
            404
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
            Bir şeyler yanlış gitti.
          </p>
          <p className="mb-4 text-lg font-light text-gray-500">
            Üzgünüz, bu sayfayı bulamıyoruz. Ana sayfada keşfedebileceğiniz bir
            çok şey bulacaksınız..
          </p>
          <Link
            to="/"
            className="inline-flex text-white bg-zinc-600 hover:bg-zinc-800 focus:ring-4 focus:outline-none focus:ring-zinc-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Ana Sayfaya Dön
          </Link>
        </div>
      </div>
    </section>
  )
}

export default NotFound
