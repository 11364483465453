import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getServices } from '../actions/serviceActions'
import {
  FaRegCalendarAlt,
  FaRegSmileWink,
  FaRegCheckCircle,
  FaRegStar
} from 'react-icons/fa'
import { IoDiamond } from 'react-icons/io5'
import TeamCarousel from '../components/TeamCarousel'
import Counter from '../components/Counter'
import PhotoGallery from '../components/PhotoGallery'

const images = [
  'barber-using-hairspray.webp',
  'barber-shaving-client.webp',
  'barber-in-elegant.webp',
  'barber-spraying-aftershave.webp',
  'barber-handsome-hairdresser.webp'
]

const HomePage = () => {
  const dispatch = useDispatch()
  const { services = [], loading = false } = useSelector(
    (state) => state.serviceReducer
  )
  const normalServices = services.filter((service) => service.vip === false)
  const vipServices = services.filter((service) => service.vip === true)

  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    dispatch(getServices())
  }, [dispatch])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      )
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-zinc-800 overflow-x-hidden">
      <main className="flex-1 w-full">
        {/* Section 1: Tam ekran ve carousel */}
        <section className="relative w-screen h-[calc(100vh-10vh)] md:h-[calc(100vh-12vh)] overflow-hidden">
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div className="absolute inset-0 flex flex-col items-center justify-center text-white z-10">
            <p className="text-lg tracking-wide font-semibold text-neutral-200">
              PROFESYONEL KADRO
            </p>
            <p className="text-lg tracking-wide font-semibold text-neutral-200">
              ÖZGÜN SAÇ TASARIMLARI
            </p>
            <h1 className="text-4xl md:text-5xl font-bold font-serif my-4">
              Unicut
            </h1>
            {/* <p className="px-8 text-lg md:text-2xl font-bold text-neutral-300 tracking-wide text-opacity-80">
              Randevu almak için giriş yapınız..
            </p> */}
            <Link
              className="flex tracking-wide items-center px-8 py-5 bg-orange-600 bg-opacity-80 hover:bg-green-600 hover:bg-opacity-80 rounded-full from-neutral-50 font-medium"
              to="/customer-dashboard"
            >
              <FaRegCalendarAlt className="mr-1" />
              RANDEVU AL
            </Link>
          </div>

          {/* Carousel yapısı */}
          <div className="w-full h-full">
            <img
              src={images[currentImageIndex]}
              alt="carousel"
              className="w-full h-full object-cover transition-all duration-1000"
            />
          </div>
        </section>

        {/* Section 2: Owner */}
        <section className="container mx-auto px-4 md:my-14">
          <div className="lg:p-30">
            <div className="bg-gradient-to-tr from-orange-400/30 via-white/20 to-red-400/30 bg-opacity-25 backdrop-blur-lg lg:border border-white border-opacity-20 shadow-2xl rounded-full lg:p-8 antialiased text-gray-900 leading-normal">
              <div className="max-w-4xl flex items-center h-auto lg:h-[calc(100vh-25vh)] flex-wrap mx-auto my-28 lg:my-0">
                {/* Main Col */}
                <div
                  id="profile"
                  className="w-full lg:w-8/12 rounded-2xl lg:rounded-l-3xl lg:rounded-r-none shadow-custom-equal-2 bg-neutral-100 mx-6 lg:mx-0"
                >
                  <div className="p-5 md:px-8 py-6 text-center lg:text-left">
                    {/* Image for mobile view */}
                    <div
                      className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-top md:-mt-20"
                      style={{
                        backgroundImage: "url('Personal-1-background.webp')"
                      }}
                    ></div>

                    <h1 className="text-2xl font-bold pt-8 lg:pt-0">
                      Uğur Çalışır
                    </h1>
                    <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-4 border-orange-700 opacity-25"></div>
                    <p className="pt-4 text-xl font-semibold flex items-center justify-center lg:justify-start">
                      İşletme Sahibi - Unicut Kurucu
                    </p>
                    <p className="pt-4 lg:text-lg font-medium text-neutral-500 ">
                      1993 yılında İstanbul Şişli'de dünyaya geldim. 10 yaşından
                      itibaren çıraklıkla başlayan esnaflık serüvenim, 21
                      yaşında İstanbul Pendik ilçesinde kendi salonumu açmamla
                      devam etti. İstanbul'da 8 yıllık işletmecilik deneyimimden
                      ve aldığım çeşitli eğitimlerden sonra, tutkulu olduğum bu
                      mesleğe genç dimağlarla devam etmek adına Eskişehir’e
                      taşındım. 2 yıldır işletmeciliğini yaptığım Unicut Hair
                      Design salonumuzda sanatımızı icra etmeye devam
                      etmekteyiz. Yeni trendleri takip etmek ve müşterilerimin
                      isteklerine göre en uygun saç modellerini tasarlamak benim
                      için bir tutkudur.
                    </p>
                  </div>
                </div>

                {/* Img Col */}
                <div className="w-full lg:w-4/12">
                  {/* Big profile image for side bar (desktop) */}
                  <img
                    src="Personal-1-background.webp"
                    className="rounded-none lg:rounded-3xl shadow-custom-equal-2 hidden lg:block"
                    alt="Profile"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section 3: Team Members */}
        <section id="team-members" className="container mx-auto px-4 md:my-14">
          <div className="flex flex-col mx-auto mb-12 md:mt-12 px-10 pb-20 md:p-20 font-sans text-gray-900 leading-normal">
            {/* Meet the Team */}
            <div className="container max-w-7xl px-4">
              {/* Section Header */}
              <div className="flex flex-wrap justify-center text-center mb-16">
                <div className="w-full lg:w-6/12 px-4">
                  {/* Header */}
                  <h1 className="text-3xl md:text-4xl font-redrose text-transparent bg-clip-text bg-gradient-to-r from-orange-300 to-red-500">
                    EKİBİMİZLE TANIŞIN
                  </h1>

                  <div className=" pt-3 border-b-4 border-orange-300 opacity-55 my-4"></div>

                  {/* Description */}
                  <p className="text-neutral-200 text-lg font-redrose">
                    Toplamda 15 yılı aşkın tecrübemizle, deneyimli bir ekibe
                    sahibiz.
                  </p>
                </div>
              </div>

              {/* Team Members */}
              <TeamCarousel />
            </div>
          </div>
        </section>

        {/* Section 4: Gallery */}
        <section className="mb-4">
          <PhotoGallery />
        </section>

        {/* Section 5: Counter */}
        <section className="container mx-auto px-4 mb-32">
          <div className="p-24s font-sans bg-zinc-800 bg-opacity-50  antialiased text-gray-900 leading-normal rounded-3xl min-w-screen flex flex-col justify-center items-center">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-10">
              <div className="text-center">
                <div className="inline-flex items-baseline">
                  <Counter end={1000} duration={3000} />
                  <span className="text-5xl font-bold text-emerald-400">+</span>
                </div>

                <div>
                  <p className="text-neutral-100 mt-2 font-medium text-2xl inline-flex items-center space-x-3">
                    <FaRegSmileWink />
                    <span>Mutlu Müşteriler</span>
                  </p>
                </div>
              </div>

              <div className="text-center">
                <div className="inline-flex items-baseline">
                  <Counter end={15} duration={3000} />
                  <span className="text-5xl font-bold text-emerald-400">+</span>
                </div>

                <div>
                  <p className="text-neutral-100 mt-2 font-medium text-2xl inline-flex items-center space-x-3 ">
                    <FaRegStar /> <span>Yıllık Tecrübe</span>
                  </p>
                </div>
              </div>

              <div className="text-center">
                <div className="inline-flex items-baseline">
                  <Counter end={5000} duration={3000} />
                  <span className="text-5xl font-bold text-emerald-400">+</span>
                </div>

                <div>
                  <p className="text-neutral-100 mt-2 font-medium text-2xl inline-flex items-center space-x-3">
                    <FaRegCheckCircle />
                    <span>Başarılı Randevular</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section 6: Prices */}
        <section className="relative bg-gradient-to-b from-gray-200 to-gray-50 py-24">
          <div className="absolute inset-0 bg-opacity-50"></div>

          <div className="absolute top-[-1px] left-0 w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 100"
              preserveAspectRatio="none"
              className="w-full h-20"
            >
              <path
                className="fill-zinc-800"
                d="M0,0 L360,100 L720,0 L1080,100 L1440,0 L1440,0 Z"
              ></path>
            </svg>
          </div>

          {/* HİZMETLER */}
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-24 pt-24">
            <div className="text-center mb-24">
              <h2
                style={{
                  WebkitTextStroke: '.7px orange'
                }}
                className="text-4xl font-redrose font-semibold text-zinc-800 sm:text-5xl my-8"
              >
                HİZMETLERİMİZ
              </h2>
              <div className="flex items-center justify-center">
                <div className="border-t-2 border-orange-300 w-1/4"></div>
                <div className="mx-4">
                  <div className="w-2 h-2 bg-orange-300 rounded-full"></div>
                </div>
                <div className="border-t-2 border-orange-300 w-1/4"></div>
              </div>
            </div>

            {/* Hizmetler ve Fiyatlar */}
            <div className="text-center text-2xl font-redrose">
              {loading ? (
                <p className="text-orange-500">Hizmetler listeleniyor...</p>
              ) : (
                <div className="px-2 md:px-6 mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
                  {/* İlk kolon */}
                  <div className="space-y-8">
                    {normalServices
                      .slice(0, Math.ceil(normalServices.length / 2))
                      .map((service, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center"
                        >
                          <span className="text-zinc-800">{service.name}</span>
                          <span className="flex-grow border-t-4 border-dotted border-orange-300 mx-4 hidden md:block"></span>
                          <span className="text-zinc-800">
                            {service.price} TL
                          </span>
                        </div>
                      ))}
                  </div>

                  {/* İkinci kolon */}
                  <div className="space-y-8">
                    {normalServices
                      .slice(Math.ceil(normalServices.length / 2))
                      .map((service, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center"
                        >
                          <span className="text-zinc-800">{service.name}</span>
                          <span className="flex-grow border-t-4 border-dotted border-orange-300 mx-4 hidden md:block"></span>
                          <span className="text-zinc-800">
                            {service.price} TL
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* VIP HİZMETLER */}
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-24 pt-24 bg-gray-900 rounded-3xl">
            <div className="text-center mb-24">
              <h2
                style={{
                  WebkitTextStroke: '1px #f9fafb'
                }}
                className="text-4xl font-redrose font-semibold text-zinc-800 sm:text-5xl my-8"
              >
                VIP - HİZMETLERİMİZ
              </h2>
              <div className="flex items-center justify-center">
                <div className="border-t border-gray-50 w-1/4"></div>
                <div className="mx-4">
                  <IoDiamond className="text-4xl md:text-5xl text-gray-50" />
                </div>
                <div className="border-t border-gray-50 w-1/4"></div>
              </div>
            </div>

            {/* VIP Hizmetler ve Fiyatlar */}
            <div className="text-center text-2xl font-redrose">
              {loading ? (
                <p className="text-gray-50">Hizmetler listeleniyor...</p>
              ) : (
                <div className="px-2 md:px-6 mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
                  {/* İlk kolon */}
                  <div className="space-y-8">
                    {vipServices
                      .slice(0, Math.ceil(vipServices.length / 2))
                      .map((service, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center"
                        >
                          <span className="text-gray-50 text-left">
                            {service.name}
                          </span>
                          <span className="flex-grow border-t-4 border-dotted border-blue-300 mx-4 hidden md:block"></span>
                          <span className="text-gray-50 whitespace-nowrap">
                            {service.price} TL
                          </span>
                        </div>
                      ))}
                  </div>

                  {/* İkinci kolon */}
                  <div className="space-y-8">
                    {vipServices
                      .slice(Math.ceil(vipServices.length / 2))
                      .map((service, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center"
                        >
                          <span className="text-gray-50 text-left">
                            {service.name}
                          </span>
                          <span className="flex-grow border-t-4 border-dotted border-blue-300 mx-4 hidden md:block"></span>
                          <span className="text-gray-50 whitespace-nowrap">
                            {service.price} TL
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default HomePage
