import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  getEmployees,
  addEmployee,
  updateEmployee,
  deleteEmployee
} from '../../actions/Admin/adminEmployeeActions'
import { REMOVE_MESSAGE } from '../../types/authTypes'
import toast from 'react-hot-toast'
import { InputMask } from '@react-input/mask'
import { GiMustache, GiReturnArrow } from 'react-icons/gi'
import { IoDiamond } from 'react-icons/io5'
import { TbDiamondOff } from 'react-icons/tb'

const EmployeeManagement = () => {
  const dispatch = useDispatch()
  const {
    employees = [],
    loading = false,
    message = ''
  } = useSelector((state) => state.adminReducer)

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '(05',
    email: '',
    password: '',
    vip: false
  })
  const [editingEmployeeId, setEditingEmployeeId] = useState(null)

  useEffect(() => {
    dispatch(getEmployees())
  }, [dispatch])

  useEffect(() => {
    const messagesArray = Array.isArray(message) ? message : [message]
    if (message.length > 0) {
      messagesArray.map((msg) => toast(msg, { icon: 'ℹ️', duration: 2000 }))
    }
    dispatch({ type: REMOVE_MESSAGE })
  }, [dispatch, message])

  const handleChangePhone = (e) => {
    const newValue = e.target.value
    if (newValue.startsWith('(05')) {
      setFormData((prevState) => ({
        ...prevState,
        phone: newValue
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        phone: '(05'
      }))
    }
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    })
  }

  const handleAddEmployee = () => {
    dispatch(addEmployee(formData))
    clearForm()
  }

  const handleUpdateEmployee = (employeeId) => {
    dispatch(updateEmployee(employeeId, formData))
    clearForm()
  }

  const handleDeleteEmployee = (employeeId) => {
    dispatch(deleteEmployee(employeeId))
  }

  const clearForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      phone: '(05',
      email: '',
      password: '',
      vip: false
    })
    setEditingEmployeeId(null)
  }

  const handleEditClick = (employee) => {
    setEditingEmployeeId(employee._id)
    setFormData({
      firstName: employee.firstName,
      lastName: employee.lastName,
      phone: employee.phone,
      email: employee.email,
      password: '',
      vip: employee.vip
    })
  }

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <div className="flex items-center mb-4">
        <Link to="/admin-dashboard" className="">
          <GiReturnArrow className="text-3xl" />
        </Link>
        <h2 className="text-2xl font-bold flex-grow flex items-center justify-center">
          Çalışan Yönetimi <GiMustache className="ml-2" />
        </h2>
      </div>

      {/* {error && <p className="text-center text-red-500">{error}</p>} */}

      {loading ? (
        <p className="text-center text-gray-500 font-semibold">
          Kayıtlı Çalışanlar Yükleniyor...
        </p>
      ) : employees && employees.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="text-left">
                <th className="py-2 pl-4 pr-2 md:px-4 border-b">Ad</th>
                <th className="py-2 px-2 md:px-4 border-b">Soyad</th>
                <th className="py-2 px-2 md:px-4 border-b">Telefon</th>
                <th className="py-2 px-2 md:px-4 border-b">Email</th>
                <th className="py-2 pr-2 border-b">VIP</th>
                <th className="py-2 px-2 md:px-4 border-b">İşlemler</th>
              </tr>
            </thead>
            <tbody>
              {employees.map((employee) => (
                <tr key={employee._id} className="hover:bg-gray-100">
                  <td className="py-2 pl-4 pr-2 md:px-4 border-b">
                    {employee.firstName}
                  </td>
                  <td className="py-2 px-2 md:px-4 border-b">
                    {employee.lastName}
                  </td>
                  <td className="py-2 px-2 md:px-4 border-b">
                    {employee.phone}
                  </td>
                  <td className="py-2 px-2 md:px-4 border-b">
                    {employee.email}
                  </td>
                  <td className="py-2 pr-2 border-b text-2xl">
                    {employee.vip ? <IoDiamond /> : <TbDiamondOff />}{' '}
                    {/* VIP durumuna göre emoji */}
                  </td>
                  <td className="py-2 px-2 md:px-4 border-b flex flex-col md:flex-row">
                    <button
                      onClick={() => handleEditClick(employee)}
                      className="bg-blue-500 text-white px-2 md:px-4 py-2 rounded-md mb-2 md:mb-0 md:mr-2 hover:bg-blue-600"
                    >
                      Güncelle
                    </button>
                    <button
                      onClick={() => handleDeleteEmployee(employee._id)}
                      className="bg-red-500 text-white px-2 md:px-4 py-2 rounded-md hover:bg-red-600"
                    >
                      Sil
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-500">Hiç çalışan bulunamadı.</p>
      )}
      <div className="flex-col mx-auto max-w-lg">
        <h3 className="text-xl font-semibold mt-6 mb-4 text-center">
          {editingEmployeeId ? 'Çalışan Güncelle' : 'Yeni Çalışan Ekle'}
        </h3>

        <form
          onSubmit={(e) => {
            e.preventDefault()
            editingEmployeeId
              ? handleUpdateEmployee(editingEmployeeId)
              : handleAddEmployee()
          }}
          className="space-y-4"
        >
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="İsim"
            required
            className="w-full px-4 py-2 border rounded-md"
          />

          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder="Soyisim"
            required
            className="w-full px-4 py-2 border rounded-md"
          />

          {/* <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChangePhone}
            placeholder="Telefon"
            required
            className="w-full px-4 py-2 border rounded-md"
          /> */}

          <InputMask
            mask="(05__) ___-__-__"
            replacement={{ _: /\d/ }}
            value={formData.phone}
            name="phone"
            onChange={handleChangePhone}
            className="w-full px-4 py-2 border rounded-md"
            placeholder="(05__) ___-__-__"
            required
          />

          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Email"
            className="w-full px-4 py-2 border rounded-md"
          />

          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            placeholder="Şifre"
            required={!editingEmployeeId}
            className="w-full px-4 py-2 border rounded-md"
          />

          {/* VIP Checkbox */}
          <label className="flex items-center">
            <input
              type="checkbox"
              name="vip"
              checked={formData.vip}
              onChange={handleInputChange}
              className="mr-2"
            />
            VIP Çalışan
          </label>

          <div className="flex justify-between">
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            >
              {editingEmployeeId ? 'Çalışanı Güncelle' : 'Yeni Çalışan Ekle'}
            </button>
            <button
              type="button"
              onClick={clearForm}
              className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
            >
              Formu Temizle
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EmployeeManagement
