import axiosInstance from '../utils/axiosInstance'
import {
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE
} from '../types/serviceTypes'

export const getServices = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SERVICES_REQUEST })

    const response = await axiosInstance.get('/services')
    dispatch({
      type: GET_SERVICES_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: GET_SERVICES_FAILURE,
      payload: error.response?.data?.message || 'Hizmetler getirilemedi'
    })
  }
}
