import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAppointments,
  getAllAppointments,
  cancelAppointment
} from '../actions/appointmentActions'
import { getEmployees } from '../actions/employeeActions'

import CustomerAppointmentList from '../components/CustomerAppointmentList'
import Calendar from '../components/Calendar'
import EmployeeSelection from '../components/EmployeeSelection'
import ServiceSelection from '../components/ServiceSelection'
import { REMOVE_MESSAGE } from '../types/authTypes'
import toast from 'react-hot-toast'

const CustomerDashboard = () => {
  const dispatch = useDispatch()
  const { message = [] } = useSelector(
    (state) => state.appointmentReducer || {}
  )

  const { user } = useSelector((state) => state.authReducer)
  const [activeTab, setActiveTab] = useState('employeeSelection')

  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [selectedService, setSelectedService] = useState(null)

  useEffect(() => {
    if (user) {
      dispatch(getAllAppointments())
    }
  }, [dispatch, user])

  useEffect(() => {
    dispatch(getEmployees())
  }, [dispatch])

  const handleCancel = async (appointmentId) => {
    await cancelAppointment(appointmentId)
    dispatch(getAppointments())
  }

  useEffect(() => {
    const messagesArray = Array.isArray(message) ? message : [message]
    if (message.length > 0) {
      messagesArray.map((msg) => toast(msg, { icon: 'ℹ️' }))
    }
    dispatch({ type: REMOVE_MESSAGE })
  }, [dispatch, message])

  const handleCalendarTab = () => {
    if (!selectedEmployee) {
      toast.error('Önce Çalışan Seçimi yapmalısınız!')
      setActiveTab('employeeSelection')
    } else if (!selectedService) {
      toast.error('Önce Hizmet Seçimi yapmalısınız!')
      setActiveTab('serviceSelection')
    } else {
      setActiveTab('calendar')
    }
  }

  return (
    <div className="">
      <div className="max-w-2xl mx-auto pt-4 pb-10 px-0 md:p-4">
        <h1 className="text-3xl font-bold text-center my-4">Müşteri Paneli</h1>
        <div className="flex justify-center space-x-2 md:space-x-4 mb-4 px-2 md:px-4 md:text-lg font-semibold">
          <button
            onClick={() => setActiveTab('employeeSelection')}
            className={`py-2 px-1 md:px-4 rounded-md transition duration-200 ${
              activeTab === 'employeeSelection'
                ? 'bg-orange-300 text-gray-900'
                : 'bg-gray-200 text-gray-500'
            }`}
          >
            👥Çalışan Seçimi
          </button>

          <button
            onClick={() => setActiveTab('serviceSelection')}
            className={`py-2 px-1 md:px-4 rounded-md transition duration-200 ${
              activeTab === 'serviceSelection'
                ? 'bg-blue-300 text-gray-900'
                : 'bg-gray-200 text-gray-500'
            }`}
          >
            🧔🏻Hizmet Seçimi
          </button>

          <button
            onClick={handleCalendarTab}
            className={`py-2 px-1 md:px-4 rounded-md transition duration-200 ${
              activeTab === 'calendar'
                ? 'bg-indigo-300 text-gray-900'
                : 'bg-gray-200 text-gray-500'
            }`}
          >
            📅Randevu Takvimi
          </button>

          <button
            onClick={() => setActiveTab('view')}
            className={`py-2 px-1 md:px-4 rounded-md transition duration-200 ${
              activeTab === 'view'
                ? 'bg-teal-400 text-gray-900'
                : 'bg-gray-200 text-gray-500'
            }`}
          >
            📜Randevumu Görüntüle
          </button>
        </div>

        <div className="bg-white p-0 md:p-6 rounded-3xl shadow-md">
          {activeTab === 'calendar' ? (
            <Calendar
              selectedEmployee={selectedEmployee?._id}
              selectedService={selectedService}
              setActiveTab={setActiveTab}
            />
          ) : activeTab === 'view' ? (
            <CustomerAppointmentList onCancel={handleCancel} />
          ) : activeTab === 'employeeSelection' ? (
            <EmployeeSelection
              selectedEmployee={selectedEmployee}
              setSelectedEmployee={setSelectedEmployee}
              setSelectedService={setSelectedService}
              setActiveTab={setActiveTab}
            />
          ) : (
            <ServiceSelection
              selectedEmployee={selectedEmployee}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              setActiveTab={setActiveTab}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomerDashboard
