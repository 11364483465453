import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  getServices,
  addService,
  updateService,
  deleteService
} from '../../actions/Admin/adminServiceActions'
import { REMOVE_MESSAGE } from '../../types/authTypes'
import toast from 'react-hot-toast'
import { PiOfficeChairFill } from 'react-icons/pi'
import { GiReturnArrow } from 'react-icons/gi'

const ServiceManagement = () => {
  const dispatch = useDispatch()
  const {
    services = [],
    loading = false,
    message = ''
  } = useSelector((state) => state.adminReducer)

  const [newService, setNewService] = useState({ name: '', price: '' })
  const [editingService, setEditingService] = useState(null)

  useEffect(() => {
    dispatch(getServices())
  }, [dispatch])

  useEffect(() => {
    const messagesArray = Array.isArray(message) ? message : [message]
    if (message.length > 0) {
      messagesArray.map((msg) => toast(msg, { icon: 'ℹ️', duration: 2000 }))
    }
    dispatch({ type: REMOVE_MESSAGE })
  }, [dispatch, message])

  const handleAddService = () => {
    if (!newService.name || !newService.price) {
      return toast.error('Hizmet Adı ve Fiyat bilgisini giriniz!')
    }
    dispatch(addService(newService))
    setNewService({ name: '', price: '' })
  }

  const handleUpdateService = () => {
    if (!editingService.name || !editingService.price) {
      return toast.error('Hizmet Adı ve Fiyat bilgisini giriniz!')
    }
    dispatch(updateService(editingService._id, editingService))
    setEditingService(null)
  }

  const handleDeleteService = (serviceId) => {
    dispatch(deleteService(serviceId))
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md lg:min-h-[calc(100vh-200px)] font-redrose">
      <div className="flex items-center mb-4">
        <Link to="/admin-dashboard" className="">
          <GiReturnArrow className="text-3xl" />
        </Link>
        <h2 className="text-2xl font-bold flex-grow flex items-center justify-center">
          Hizmet Yönetimi <PiOfficeChairFill className="ml-2" />
        </h2>
      </div>

      <div className="flex flex-col sm:flex-row items-center justify-center mb-6">
        <input
          type="text"
          placeholder="Hizmet Adı"
          value={newService.name}
          onChange={(e) =>
            setNewService({ ...newService, name: e.target.value })
          }
          className="border-2 border-orange-300 rounded-lg p-2 mb-4 sm:mb-0 sm:mr-4 w-full sm:w-64"
        />
        <input
          type="number"
          placeholder="Fiyat"
          value={newService.price}
          onChange={(e) =>
            setNewService({ ...newService, price: e.target.value })
          }
          className="border-2 border-orange-300 rounded-lg p-2 mb-4 sm:mb-0 sm:mr-4 w-full sm:w-32"
        />
        <button
          onClick={handleAddService}
          className="bg-zinc-800 border-2 border-orange-300 hover:border-zinc-800 text-orange-300 hover:text-zinc-800 py-2 px-4 rounded-lg hover:bg-orange-300"
        >
          Hizmet Ekle
        </button>
      </div>

      <ul className="space-y-4 max-w-3xl flex flex-col mx-auto">
        {loading ? (
          <p className="text-center text-gray-500 font-semibold">
            Eklenmiş Hizmetler Yükleniyor...
          </p>
        ) : Array.isArray(services) && services.length > 0 ? (
          services.map((service) => (
            <li
              key={service._id}
              className="flex flex-col md:flex-row items-center justify-between bg-gray-100 p-4 rounded-lg shadow-md"
            >
              {editingService?._id === service._id ? (
                <div className="flex flex-col sm:flex-row items-center w-full">
                  <input
                    type="text"
                    value={editingService?.name}
                    onChange={(e) =>
                      setEditingService({
                        ...editingService,
                        name: e.target.value
                      })
                    }
                    className="border border-orange-300 rounded-lg p-2 mb-4 sm:mb-0 sm:mr-4 w-full sm:w-64"
                  />
                  <input
                    type="number"
                    value={editingService?.price}
                    onChange={(e) =>
                      setEditingService({
                        ...editingService,
                        price: e.target.value
                      })
                    }
                    className="border border-orange-300 rounded-lg p-2 mb-4 sm:mb-0 sm:mr-4 w-full sm:w-32 font-semibold"
                  />

                  <div className="mx-auto md:mx-0 md:ml-auto">
                    <button
                      onClick={handleUpdateService}
                      className="bg-green-500 text-white py-2 w-[90px] rounded-lg hover:bg-green-600"
                    >
                      Kaydet
                    </button>

                    <button
                      onClick={() => setEditingService(null)}
                      className="bg-gray-500 text-white py-2 w-16 ml-4 rounded-lg hover:bg-gray-600"
                    >
                      İptal
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex items-center w-full justify-center">
                    <span className="text-lg">{service.name}</span>
                    <span className="flex-grow border-t-4 border-dotted border-orange-300 mx-6 hidden md:block"></span>
                    <span className="text-lg ml-auto whitespace-nowrap font-semibold">
                      {service.price} TL
                    </span>
                  </div>

                  <div className="flex space-x-4 mt-4 md:mt-0 ml-4">
                    <button
                      onClick={() => setEditingService(service)}
                      className="bg-yellow-500 text-white py-2 w-[90px] rounded-lg hover:bg-yellow-600"
                    >
                      Düzenle
                    </button>

                    <button
                      onClick={() => handleDeleteService(service._id)}
                      className="bg-red-500 text-white py-2 w-16 rounded-lg hover:bg-red-600"
                    >
                      Sil
                    </button>
                  </div>
                </>
              )}
            </li>
          ))
        ) : (
          <p className="font-semibold text-center">Henüz hizmet eklenmemiş.</p>
        )}
      </ul>
    </div>
  )
}

export default ServiceManagement
