import React, { useState } from 'react'
import { sendContactMessage } from '../actions/contactActions'

const ContactForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    subject: '',
    message: ''
  })

  const handleChange = (e) => {
    const { id, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { email, subject, message } = formData
    if (email && subject && message) {
      sendContactMessage({
        email,
        subject,
        message
      })
    }
  }

  return (
    <section className="bg-white opacity-85 rounded-3xl">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl font-extrabold text-center text-charcoal">
          Bize Ulaşın
        </h2>
        <p className="mb-8 lg:mb-16 text-center text-gray-900 sm:text-xl">
          Stilinizi bulmak konusunda bir sorun mu yaşıyorsunuz? Profesyonel
          değerlendirmeye mi ihtiyacınız var? Bizimle iletişime geçin!
        </p>
        <form onSubmit={handleSubmit} className="space-y-8">
          <div>
            <label
              htmlFor="email"
              className="block mb-2 font-medium text-lg text-gray-900"
            >
              Mail Adresiniz
            </label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="shadow-sm bg-gray-500 border border-gray-600 text-white rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 placeholder-gray-300 shadow-sm-light"
              placeholder="name@gmail.com"
              required
            />
          </div>
          <div>
            <label
              htmlFor="subject"
              className="block mb-2 font-medium text-lg text-gray-900"
            >
              Konu
            </label>
            <input
              type="text"
              id="subject"
              value={formData.subject}
              onChange={handleChange}
              className="block p-3 w-full text-white bg-gray-500 rounded-lg border border-gray-600 shadow-sm focus:ring-primary-500 focus:border-primary-500 placeholder-gray-300 shadow-sm-light"
              placeholder="Size nasıl yardımcı olabileceğimizden bahsedin"
              required
            />
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block mb-2 font-medium text-lg text-gray-900"
            >
              Mesajınız
            </label>
            <textarea
              id="message"
              rows="6"
              value={formData.message}
              onChange={handleChange}
              className="block p-2.5 w-full text-white bg-gray-500 rounded-lg shadow-sm border border-gray-600 focus:ring-primary-500 focus:border-primary-500 placeholder-gray-300"
              placeholder="Mesajınızı yazın..."
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-zinc-800 hover:bg-neutral-100 text-white hover:text-charcoal hover:outline-double font-semibold text-base md:text-lg transition duration-300 ease-in-out mx-auto px-3 md:px-4 py-1 md:py-6 rounded-lg h-9 md:h-10 flex items-center justify-center whitespace-nowrap flex-shrink-0"
          >
            Mesajı Gönder
          </button>
        </form>
      </div>
    </section>
  )
}

export default ContactForm
