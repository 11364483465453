import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { registerUser } from '../actions/authActions'
import { REMOVE_MESSAGE } from '../types/authTypes'
import toast from 'react-hot-toast'

import { InputMask } from '@react-input/mask'

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    email: '',
    phone: '(05'
  })

  const { message, loading, isAuthenticated } = useSelector(
    (state) => state.authReducer
  )

  const dispatch = useDispatch()

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'phone') {
      if (value.startsWith('(05')) {
        setFormData((prevData) => ({
          ...prevData,
          phone: value
        }))
      } else {
        setFormData((prevData) => ({
          ...prevData,
          phone: '(05'
        }))
      }
    } else if (name === 'firstName' || name === 'lastName') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: capitalizeFirstLetter(value)
      }))
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }

  const capitalizeFirstLetter = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    let { firstName, lastName, phone, password, confirmPassword, email } =
      formData

    firstName = firstName.trim()
    lastName = lastName.trim()
    phone = phone.trim().replace(/\D/g, '')
    password = password.trim()
    confirmPassword = confirmPassword.trim()
    email = email.trim()

    firstName = capitalizeFirstLetter(firstName)
    lastName = capitalizeFirstLetter(lastName)

    if (!firstName || !lastName || !phone || !password || !email) {
      return toast.error('Tüm alanlar doldurulmalıdır.')
    }

    if (firstName.length > 20 || lastName.length > 20) {
      return toast.error('Ad veya soyad 20 karakteri aşmamalıdır.')
    }

    if (phone.length !== 11) {
      return toast.error('Telefon numarası tam 11 haneli olmalıdır.')
    }

    if (!isValidEmail(email)) {
      return toast.error('Geçerli bir email adresi giriniz.')
    }

    if (password.length < 6) {
      return toast.error('Şifre en az 6 karakter olmalıdır.')
    }
    if (password !== confirmPassword) {
      return toast.error('Şifreler eşleşmiyor.')
    }

    dispatch(
      registerUser({
        firstName,
        lastName,
        phone,
        password,
        email
      })
    )
  }

  useEffect(() => {
    if (message) {
      const messagesArray = Array.isArray(message) ? message : [message]
      messagesArray.forEach((msg) => {
        toast(msg, { icon: 'ℹ️' })
      })
    }

    dispatch({ type: REMOVE_MESSAGE })
  }, [dispatch, message])

  const navigate = useNavigate()
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/customer-dashboard')
    }
  }, [isAuthenticated, navigate])

  return (
    <div className="bg-zinc-800 p-6 md:p-20 lg:min-h-[calc(100vh-200px)]">
      <div className="w-full max-w-sm mx-auto overflow-hidden bg-zinc-800 rounded-lg shadow-md outline outline-1 outline-charcoal">
        <div className="px-8 pt-4 pb-6">
          <h3 className="my-6 text-2xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-sky-300 to-yellow-200">
            Hesap Oluştur
          </h3>

          <form onSubmit={handleSubmit}>
            <div className="mt-4">
              <input
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                name="firstName"
                placeholder="Ad"
                onChange={handleChange}
              />
            </div>

            <div className="mt-4">
              <input
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                name="lastName"
                placeholder="Soyad"
                onChange={handleChange}
              />
            </div>

            {/* Telefon Numarası Maskesi */}
            <div className="mt-4">
              {/* <InputMask
                mask="(0599) 999-9999"
                maskChar={null}
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                name="phone"
                placeholder="(05__) ___-____"
                value={formData.phone}
                onChange={handleChange}
              /> */}

              <InputMask
                mask="(05__) ___-__-__"
                replacement={{ _: /\d/ }}
                value={formData.phone}
                name="phone"
                onChange={handleChange}
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                placeholder="(05__) ___-__-__"
              />
            </div>

            <div className="mt-4">
              <input
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                type="password"
                name="password"
                placeholder="Şifre"
                onChange={handleChange}
              />
            </div>

            <div className="mt-4">
              <input
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                type="password"
                name="confirmPassword"
                placeholder="Şifre Tekrar"
                onChange={handleChange}
              />
            </div>

            <div className="mt-4">
              <input
                type="email"
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                name="email"
                placeholder="E-Posta Adresi"
                onChange={handleChange}
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="block w-full px-4 py-2 mt-6 text-white font-semibold tracking-wide rounded-lg bg-gradient-to-r from-sky-500 to-orange-sky-700 hover:from-sky-400"
            >
              {loading ? 'Kayıt Yapılıyor...' : 'Kayıt Ol'}
            </button>
          </form>
        </div>
        <div className="flex items-center justify-center py-4 text-center bg-orange-300">
          <span className="text-sm text-zinc-800 font-medium">
            Şifrenizi not etmeyi unutmayınız!
          </span>
        </div>
      </div>
    </div>
  )
}

export default RegisterPage
