import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllAppointments,
  employeeCreatedAppointment
} from '../actions/appointmentActions'
import { getEmployees } from '../actions/employeeActions'
import { getClosedSlots } from '../actions/closedActions'
import { getServices } from '../actions/serviceActions'
import { format, addMonths, subMonths } from 'date-fns'
import { tr } from 'date-fns/locale'
import LoadingSpinner from './LoadingSpinner'
import { REMOVE_MESSAGE } from '../types/authTypes'
import toast from 'react-hot-toast'
import { InputMask } from '@react-input/mask'

const EmployeeAppointmentCalendar = () => {
  const dispatch = useDispatch()
  const {
    appointments = [],
    loading = false,
    message = ''
  } = useSelector((state) => state.appointmentReducer || {})
  const { employees = [] } = useSelector((state) => state.employeeReducer || {})
  const { services } = useSelector((state) => state.serviceReducer || {})
  const { closedSlots = [] } = useSelector((state) => state.closedReducer || {})
  const [currentDate, setCurrentDate] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedEmployee, setSelectedEmployee] = useState('')
  const [selectedService, setSelectedService] = useState('')
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [customerInfo, setCustomerInfo] = useState({
    firstName: '',
    lastName: '',
    phone: '(05'
  })

  const [showCustomerForm, setShowCustomerForm] = useState(false)

  useEffect(() => {
    dispatch(getAllAppointments())
    dispatch(getEmployees())
    dispatch(getServices())

    if (selectedEmployee) {
      dispatch(getClosedSlots(selectedEmployee))
    }
  }, [dispatch, selectedEmployee])

  useEffect(() => {
    const messagesArray = Array.isArray(message) ? message : [message]
    if (message.length > 0) {
      messagesArray.map((msg) => toast(msg, { icon: 'ℹ️' }))
    }
    dispatch({ type: REMOVE_MESSAGE })
  }, [dispatch, message])

  const timeSlots = [
    '10:00-11:00',
    '11:00-12:00',
    '12:00-13:00',
    '13:00-14:00',
    '14:00-15:00',
    '15:00-16:00',
    '16:00-17:00',
    '17:00-18:00',
    '18:00-19:00',
    '19:00-20:00',
    '20:00-21:00',
    '21:00-22:00',
    '22:00-23:00'
  ]

  const handlePrevMonth = () => setCurrentDate(subMonths(currentDate, 1))
  const handleNextMonth = () => setCurrentDate(addMonths(currentDate, 1))

  const closeModal = () => {
    setIsModalOpen(false)
    setSelectedTimeSlot('')
    setCustomerInfo({ firstName: '', lastName: '', phone: '(05' })
    setShowCustomerForm(false)
    document.activeElement.blur()
  }

  const handleDayClick = (day, dolulukOrani) => {
    if (!selectedEmployee || !selectedService) return

    const selectedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    )
    const weekDay = selectedDate.getDay()

    if (dolulukOrani === 100 || weekDay === 0) return

    setSelectedDate(selectedDate)
    setIsModalOpen(true)
  }

  const handleTimeSlotClick = (slot) => {
    setSelectedTimeSlot(slot)
  }

  const handleServiceChange = (e) => setSelectedService(e.target.value)

  const handleNextStep = () => {
    if (!selectedTimeSlot) {
      toast.error('Lütfen randevu saatini seçiniz!')
      return
    }

    if (!selectedDate || !selectedEmployee || !selectedService) {
      toast.error('Lütfen tüm bilgileri doldurunuz!')
      return
    }

    setCustomerInfo({ firstName: '', lastName: '', phone: '(05' })

    setShowCustomerForm(true)
  }

  const handleChangePhone = (e) => {
    const newValue = e.target.value
    if (newValue.startsWith('(05')) {
      setCustomerInfo((prevState) => ({
        ...prevState,
        phone: newValue
      }))
    } else {
      setCustomerInfo((prevState) => ({
        ...prevState,
        phone: '(05'
      }))
    }
  }

  const handleCustomerInfoSubmit = () => {
    if (
      !customerInfo.firstName ||
      !customerInfo.lastName ||
      !customerInfo.phone
    ) {
      toast.error('Lütfen müşteri bilgilerini eksiksiz giriniz!')
      return
    }

    const formattedDate = format(selectedDate, 'yyyy-MM-dd')

    let trimmedPhone = customerInfo.phone.trim().replace(/\D/g, '')
    if (trimmedPhone.length !== 11) {
      return toast.error('Telefon numarası tam 11 haneli olmalıdır.')
    }
    if (!trimmedPhone) {
      return toast.error('Telefon girilmelidir.')
    }

    dispatch(
      employeeCreatedAppointment({
        employee: selectedEmployee,
        service: selectedService,
        date: formattedDate,
        timeSlot: selectedTimeSlot,
        firstName: customerInfo.firstName,
        lastName: customerInfo.lastName,
        phone: trimmedPhone
      })
    )
      .then(() => {
        closeModal()
      })
      .catch((err) => {
        toast.error('Randevu oluşturulurken bir hata oluştu.')
      })
  }

  const renderAppointmentsForDay = (day) => {
    const dayAppointments = appointments.filter(
      (appointment) =>
        new Date(appointment.date).getDate() === day &&
        new Date(appointment.date).getMonth() === currentDate.getMonth() &&
        new Date(appointment.date).getFullYear() ===
          currentDate.getFullYear() &&
        appointment.employee === selectedEmployee
    )

    const doluRandevuSayisi = dayAppointments.length

    const dayCloseds = closedSlots.filter(
      (closedslot) =>
        new Date(closedslot.date).getDate() === day &&
        new Date(closedslot.date).getMonth() === currentDate.getMonth() &&
        new Date(closedslot.date).getFullYear() === currentDate.getFullYear() &&
        closedslot.employee === selectedEmployee
    )

    const randevuyaKapaliSayisi = dayCloseds[0]?.timeSlots
      ? dayCloseds[0]?.timeSlots.length
      : dayCloseds

    const isFullDayClosed = dayCloseds ? dayCloseds[0]?.fullDay : false

    const toplamDoluVeKapali = doluRandevuSayisi + randevuyaKapaliSayisi
    const dolulukOrani = isFullDayClosed
      ? 100
      : (toplamDoluVeKapali / timeSlots.length) * 100

    const dayDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    )
    const weekDay = dayDate.getDay()
    const isSunday = weekDay === 0

    return (
      <div
        key={day}
        onClick={() => handleDayClick(day, dolulukOrani)}
        className="py-3 md:p-4 border rounded-lg cursor-pointer day-box transition-colors duration-300 ease-in-out"
        style={{
          '--dolulukOrani': `${dolulukOrani}%`,
          '--opacity':
            isSunday || !selectedEmployee || !selectedService ? 0.5 : 1,
          '--pointer-events':
            selectedEmployee && selectedService ? 'auto' : 'none',
          cursor: isSunday || isFullDayClosed ? 'not-allowed' : 'pointer'
        }}
      >
        <span className="font-bold text-gray-800">{day}</span>
      </div>
    )
  }

  const renderCalendarDays = () => {
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    )
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    )
    const daysInMonth = Array.from(
      { length: endOfMonth.getDate() },
      (_, i) => i + 1
    )

    const startDayOfWeek = (startOfMonth.getDay() + 6) % 7

    const emptyDays = Array.from({ length: startDayOfWeek }, (_, i) => (
      <div key={`empty-${i}`} className="p-4" />
    ))

    const days = daysInMonth.map(renderAppointmentsForDay)

    return [...emptyDays, ...days]
  }

  const renderTimeSlots = () => {
    const dayAppointments = appointments.filter(
      (appointment) =>
        new Date(appointment.date).getDate() === selectedDate.getDate() &&
        new Date(appointment.date).getMonth() === selectedDate.getMonth() &&
        new Date(appointment.date).getFullYear() ===
          selectedDate.getFullYear() &&
        appointment.employee === selectedEmployee
    )

    const dayCloseds = closedSlots.filter(
      (closedslot) =>
        new Date(closedslot.date).getDate() === selectedDate.getDate() &&
        new Date(closedslot.date).getMonth() === selectedDate.getMonth() &&
        new Date(closedslot.date).getFullYear() ===
          selectedDate.getFullYear() &&
        closedslot.employee === selectedEmployee
    )

    const closedTimeSlots = dayCloseds[0]?.timeSlots || []

    return (
      <div
        className={`grid grid-cols-2 gap-4 mt-4 md:grid-cols-4 ${
          showCustomerForm ? 'hidden' : ''
        }`}
      >
        {timeSlots.map((slot) => {
          const isSlotBooked = dayAppointments.some(
            (appointment) => appointment.timeSlot === slot
          )
          const isSlotClosed = closedTimeSlots.includes(slot)
          const isSelected = slot === selectedTimeSlot
          return (
            <div
              key={slot}
              onClick={() => handleTimeSlotClick(slot)}
              className={`p-2 border rounded-t-3xl text-center cursor-pointer transition-colors duration-300 ease-in-out font-semibold ${
                isSlotBooked
                  ? 'bg-red-600 text-white pointer-events-none'
                  : isSlotClosed
                  ? 'bg-red-600 text-white pointer-events-none'
                  : isSelected
                  ? 'bg-blue-500 text-white'
                  : 'bg-green-500 text-white'
              } hover:bg-blue-300`}
            >
              {slot}
            </div>
          )
        })}
      </div>
    )
  }

  if (loading) {
    return (
      <div className="font-medium pl-4">
        Yükleniyor... <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className="max-w-4xl mx-auto px-2 md:p-6 bg-gray-50 rounded-3xl shadow-md">
      <div className="pt-2 md:pt-0 flex justify-between ">
        <select
          value={selectedEmployee}
          onChange={(e) => setSelectedEmployee(e.target.value)}
          required
          className="mt-2 mb-4 md:mb-6 block w-5/12 py-2 px-3 border border-gray-300 bg-orange-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-75 focus:border-indigo-500 transition duration-300 ease-in-out transform md:hover:scale-105"
        >
          <option value="" disabled>
            👥 Bir Çalışan Seçiniz
          </option>
          {employees.length > 0 ? (
            employees.map((employee) => (
              <option key={employee._id} value={employee._id}>
                {employee.firstName} {employee.lastName}
                {employee.vip ? ' - 💎VIP' : ''}
              </option>
            ))
          ) : (
            <option value="" disabled>
              Çalışan bulunamadı
            </option>
          )}
        </select>

        {/* Hizmet Seçimi */}
        <select
          value={selectedService}
          onChange={handleServiceChange}
          disabled={!selectedEmployee}
          className="mt-2 mb-4 md:mb-6 block w-6/12 py-2 px-3 border border-gray-300 bg-blue-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-75 focus:border-indigo-500 transition duration-300 ease-in-out transform md:hover:scale-105"
        >
          <option value="" disabled>
            🧔🏻 Bir Hizmet Seçiniz
          </option>
          {services
            .filter(
              (service) =>
                service.vip ===
                employees.find((e) => e._id === selectedEmployee)?.vip
            )
            .map((service) => (
              <option key={service._id} value={service._id}>
                {service.name} - {service.price}₺
              </option>
            ))}
        </select>
      </div>

      <div className="flex justify-between items-center mb-6 mx-6">
        <button
          onClick={handlePrevMonth}
          className="text-2xl font-bold text-gray-700 hover:text-gray-900"
        >
          &lt;
        </button>
        <h2 className="text-3xl font-semibold">
          {format(currentDate, 'MMMM yyyy', { locale: tr })}
        </h2>
        <button
          onClick={handleNextMonth}
          className="text-2xl font-bold text-gray-700 hover:text-gray-900"
        >
          &gt;
        </button>
      </div>

      <div className="grid grid-cols-7 gap-x-2 gap-y-3 md:gap-4 mb-4 text-center font-semibold">
        {['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'].map((day) => (
          <div key={day} className="">
            {day}
          </div>
        ))}
        {renderCalendarDays()}
      </div>

      {/* Takvimin altındaki mesajlar */}
      {!selectedEmployee ? (
        <p className="text-center font-bold text-lg text-amber-500 pb-4 md:pb-0">
          👥 Önce Çalışan Seçiniz!
        </p>
      ) : !selectedService ? (
        <p className="text-center font-bold text-lg text-blue-500 pb-4 md:pb-0">
          🧔🏻 Şimdi Hizmet Seçiniz!
        </p>
      ) : (
        <p className="text-center font-bold text-lg text-green-500 pb-4 md:pb-0">
          ✨ Gün Seçimi Yapabilirsiniz
        </p>
      )}

      {isModalOpen && (
        <div className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-3xl shadow-lg max-w-sm md:max-w-xl w-full">
            <h2 className="text-lg font-semibold mb-4 text-center">
              Randevu Al: {format(selectedDate, 'PPPP', { locale: tr })}
            </h2>
            {renderTimeSlots()}
            <div className="font-semibold">
              {/* Müşteri Bilgileri Formu */}
              {showCustomerForm && (
                <div className="mt-4 md:mx-20">
                  <h2 className="text-lg mb-2 text-gray-700 text-center">
                    Müşteri Bilgileri
                  </h2>
                  <div className="mb-4">
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Ad
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      value={customerInfo.firstName}
                      onChange={(e) =>
                        setCustomerInfo({
                          ...customerInfo,
                          firstName: e.target.value
                        })
                      }
                      className="w-full border border-gray-300 p-2 rounded-lg focus:outline-none focus:border-green-700"
                      placeholder="Müşteri Adı"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Soyad
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      value={customerInfo.lastName}
                      onChange={(e) =>
                        setCustomerInfo({
                          ...customerInfo,
                          lastName: e.target.value
                        })
                      }
                      className="w-full border border-gray-300 p-2 rounded-lg focus:outline-none focus:border-green-700"
                      placeholder="Müşteri Soyadı"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Telefon
                    </label>
                    {/* <input
                      type="text"
                      id="phone"
                      value={customerInfo.phone}
                      onChange={(e) =>
                        setCustomerInfo({
                          ...customerInfo,
                          phone: e.target.value
                        })
                      }
                      className="w-full border border-gray-300 p-2 rounded-lg focus:outline-none"
                      required
                    /> */}

                    <InputMask
                      mask="(05__) ___-__-__"
                      replacement={{ _: /\d/ }}
                      value={customerInfo.phone}
                      name="phone"
                      onChange={handleChangePhone}
                      className="w-full border border-gray-300 p-2 rounded-lg focus:outline-none focus:border-green-700"
                      placeholder="(05__) ___-__-__"
                      required
                    />
                  </div>
                  <button
                    onClick={handleCustomerInfoSubmit}
                    className={`w-full py-2 px-4 rounded-xl font-semibold text-white ${
                      customerInfo.firstName &&
                      customerInfo.lastName &&
                      customerInfo.phone
                        ? 'bg-green-500'
                        : 'bg-gray-400 cursor-not-allowed'
                    }`}
                    disabled={
                      !customerInfo.firstName ||
                      !customerInfo.lastName ||
                      !customerInfo.phone
                    }
                  >
                    Onayla
                  </button>
                </div>
              )}
            </div>

            <div className="mt-4 flex justify-end font-semibold">
              {/* <button
                className="bg-gray-500 hover:bg-gray-600 text-white p-4 rounded-xl mr-4"
                onClick={closeModal} 
              >
                İptal
              </button> */}

              {/* <button
                className="bg-blue-500 hover:bg-blue-600 text-white p-4 rounded-xl"
                onClick={handleCreateAppointment}
              >
                Onayla
              </button> */}

              <button
                onClick={closeModal}
                className="bg-gray-500 hover:bg-gray-600 text-white p-4 rounded-xl mr-8"
              >
                İptal
              </button>

              {showCustomerForm ? (
                <button
                  className="py-4 px-8 bg-red-500 hover:bg-red-600 text-white rounded-xl"
                  onClick={() => setShowCustomerForm(false)}
                >
                  Geri
                </button>
              ) : (
                <button
                  onClick={handleNextStep}
                  className={`py-4 px-8 text-white rounded-xl ${
                    selectedTimeSlot
                      ? 'bg-blue-500 hover:bg-blue-600 text-white'
                      : 'bg-gray-400 cursor-not-allowed text-white'
                  }`}
                  disabled={!selectedTimeSlot}
                >
                  İleri
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EmployeeAppointmentCalendar
