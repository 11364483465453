import axiosInstance from '../utils/axiosInstance'
import toast from 'react-hot-toast'

import {
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_SUCCESS,
  CREATE_APPOINTMENT_FAILURE,
  GET_APPOINTMENTS_REQUEST,
  GET_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENTS_FAILURE,
  GET_ALL_APPOINTMENTS_REQUEST,
  GET_ALL_APPOINTMENTS_SUCCESS,
  GET_ALL_APPOINTMENTS_FAILURE,
  UPDATE_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILURE,
  DELETE_APPOINTMENT_REQUEST,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_APPOINTMENT_FAILURE,
  SET_MESSAGE,
  REMOVE_MESSAGE
} from '../types/appointmentTypes'

export const employeeCreatedAppointment =
  (appointmentData) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_APPOINTMENT_REQUEST })

      const { data } = await axiosInstance.post(
        '/appointments/employeeCreatedAppointment',
        appointmentData
      )
      dispatch({ type: CREATE_APPOINTMENT_SUCCESS, payload: data })
      setTimeout(() => dispatch({ type: REMOVE_MESSAGE }), 1000)
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Randevu oluştururken bir hata oldu.'

      dispatch({
        type: CREATE_APPOINTMENT_FAILURE,
        payload: message
      })

      setTimeout(() => dispatch({ type: REMOVE_MESSAGE }), 1000)
    }
  }

export const createAppointment = (appointmentData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_APPOINTMENT_REQUEST })

    const { data } = await axiosInstance.post('/appointments', appointmentData)
    dispatch({ type: CREATE_APPOINTMENT_SUCCESS, payload: data })
    setTimeout(() => dispatch({ type: REMOVE_MESSAGE }), 1000)
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Randevu oluştururken bir hata oldu.'

    dispatch({
      type: CREATE_APPOINTMENT_FAILURE,
      payload: message
    })

    setTimeout(() => dispatch({ type: REMOVE_MESSAGE }), 1000)
  }
}

export const getAppointments = () => async (dispatch) => {
  try {
    dispatch({ type: GET_APPOINTMENTS_REQUEST })
    const { data } = await axiosInstance.get('/appointments')

    dispatch({ type: GET_APPOINTMENTS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Randevular Listelenirken bir hata oldu.'

    dispatch({
      type: GET_APPOINTMENTS_FAILURE,
      payload: message
    })
    setTimeout(() => dispatch({ type: REMOVE_MESSAGE }), 1000)
  }
}

export const getAllAppointments = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_APPOINTMENTS_REQUEST })
    const { data } = await axiosInstance.get('/appointments/all')
    dispatch({ type: GET_ALL_APPOINTMENTS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Randevular Listelenirken bir hata oldu.'
    dispatch({
      type: GET_ALL_APPOINTMENTS_FAILURE,
      payload: message
    })
    setTimeout(() => dispatch({ type: REMOVE_MESSAGE }), 1000)
  }
}

export const cancelAppointment = async (appointmentId) => {
  try {
    await axiosInstance.patch(`/appointments/${appointmentId}/cancel`)
    toast.success('Randevunuz iptal edildi.')
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Randevu iptal edilirken bir hata oldu.'
    toast.error(message)
  }
}
