import axiosInstance from '../../utils/axiosInstance'
import {
  ADMIN_GET_SERVICES_REQUEST,
  ADMIN_GET_SERVICES_SUCCESS,
  ADMIN_GET_SERVICES_FAILURE,
  ADMIN_ADD_SERVICE_REQUEST,
  ADMIN_ADD_SERVICE_SUCCESS,
  ADMIN_ADD_SERVICE_FAILURE,
  ADMIN_UPDATE_SERVICE_REQUEST,
  ADMIN_UPDATE_SERVICE_SUCCESS,
  ADMIN_UPDATE_SERVICE_FAILURE,
  ADMIN_DELETE_SERVICE_REQUEST,
  ADMIN_DELETE_SERVICE_SUCCESS,
  ADMIN_DELETE_SERVICE_FAILURE
} from '../../types/adminTypes'

export const getServices = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_GET_SERVICES_REQUEST })

    const response = await axiosInstance.get('/admin/services')
    dispatch({
      type: ADMIN_GET_SERVICES_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: ADMIN_GET_SERVICES_FAILURE,
      payload: error.response?.data?.message || 'Hizmetler getirilemedi'
    })
  }
}

export const getVipServices = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_GET_SERVICES_REQUEST })

    const response = await axiosInstance.get('/admin/services?vip=true')
    dispatch({
      type: ADMIN_GET_SERVICES_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: ADMIN_GET_SERVICES_FAILURE,
      payload: error.response?.data?.message || 'VIP hizmetler getirilemedi'
    })
  }
}

export const addService = (service) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_ADD_SERVICE_REQUEST })

    const response = await axiosInstance.post('/admin/services', service)
    dispatch({
      type: ADMIN_ADD_SERVICE_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: ADMIN_ADD_SERVICE_FAILURE,
      payload: error.response?.data?.message || 'Hizmet eklenemedi'
    })
  }
}

export const updateService = (serviceId, serviceData) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_UPDATE_SERVICE_REQUEST })

    const response = await axiosInstance.patch(
      `/admin/services/${serviceId}`,
      serviceData
    )
    dispatch({
      type: ADMIN_UPDATE_SERVICE_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: ADMIN_UPDATE_SERVICE_FAILURE,
      payload: error.response?.data?.message || 'Hizmet güncellenemedi'
    })
  }
}

export const deleteService = (serviceId) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_DELETE_SERVICE_REQUEST })

    const response = await axiosInstance.delete(`/admin/services/${serviceId}`)
    dispatch({
      type: ADMIN_DELETE_SERVICE_SUCCESS,
      payload: { serviceId, message: response.data.message }
    })
  } catch (error) {
    dispatch({
      type: ADMIN_DELETE_SERVICE_FAILURE,
      payload: error.response?.data?.message || 'Hizmet silinemedi'
    })
  }
}
