import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  getCustomers,
  updateCustomer,
  deleteCustomer
} from '../../actions/Admin/adminCustomerActions'
import axiosInstance from '../../utils/axiosInstance'
import toast from 'react-hot-toast'
import { FaUsers } from 'react-icons/fa'
import { GiReturnArrow } from 'react-icons/gi'

const CustomerManagement = () => {
  const dispatch = useDispatch()
  const { customers = [], loading = false } = useSelector(
    (state) => state.adminReducer
  )

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: ''
  })
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredCustomers, setFilteredCustomers] = useState([])
  const [editingCustomerId, setEditingCustomerId] = useState(null)

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5

  const totalPages = Math.ceil(filteredCustomers?.length / itemsPerPage)

  useEffect(() => {
    dispatch(getCustomers())
  }, [dispatch])

  useEffect(() => {
    if (customers) {
      let filtered = customers

      if (searchQuery.trim() !== '') {
        const terms = searchQuery
          .toLowerCase()
          .split(' ')
          .filter((term) => term)

        filtered = customers.filter((customer) => {
          return terms.every(
            (term) =>
              customer.firstName.toLowerCase().includes(term) ||
              customer.lastName.toLowerCase().includes(term) ||
              customer.email.toLowerCase().includes(term) ||
              customer.phone.includes(term)
          )
        })
      }

      setFilteredCustomers(filtered)
    }
  }, [customers, searchQuery])

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleUpdateCustomer = (customerId) => {
    dispatch(updateCustomer(customerId, formData))
    clearForm()
  }

  const handleDeleteCustomer = (customerId) => {
    dispatch(deleteCustomer(customerId))
  }

  const clearForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: ''
    })
    setEditingCustomerId(null)
  }

  const handleEditClick = (customer) => {
    setEditingCustomerId(customer._id)
    setFormData({
      firstName: customer.firstName,
      lastName: customer.lastName,
      phone: customer.phone,
      email: customer.email,
      password: ''
    })
  }

  const handleExport = async () => {
    try {
      const response = await axiosInstance.get('/admin/customers/export', {
        responseType: 'blob'
      })

      const contentType = response.headers['content-type']
      if (
        contentType ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'MusterilerTablosu.xlsx')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)

        toast.success('Excel dosyası başarıyla indirildi!')
      } else {
        const reader = new FileReader()
        reader.onload = () => {
          try {
            const errorData = JSON.parse(reader.result)
            const message =
              errorData.message || 'Excel dosyası indirilirken bir hata oluştu.'
            toast.error(message)
          } catch (e) {
            toast.error('Excel dosyası indirilirken bir hata oluştu.')
          }
        }
        reader.readAsText(response.data)
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const contentType = error.response.headers['content-type']
        if (contentType === 'application/json') {
          const message =
            error.response.data.message ||
            'Excel dosyası indirilirken bir hata oluştu.'
          toast.error(message)
        } else {
          const reader = new FileReader()
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result)
              const message =
                errorData.message ||
                'Excel dosyası indirilirken bir hata oluştu.'
              toast.error(message)
            } catch (e) {
              toast.error('Excel dosyası indirilirken bir hata oluştu.')
            }
          }
          reader.readAsText(error.response.data)
        }
      } else {
        toast.error('Excel dosyası indirilirken bir hata oluştu.')
      }
    }
  }

  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
  const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1))
  const handleFirstPage = () => setCurrentPage(1)
  const handleLastPage = () => setCurrentPage(totalPages)

  const startIndex = (currentPage - 1) * itemsPerPage
  const currentCustomers = filteredCustomers.slice(
    startIndex,
    startIndex + itemsPerPage
  )

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <div className="flex items-center mb-4">
        <Link to="/admin-dashboard" className="">
          <GiReturnArrow className="text-3xl" />
        </Link>
        <h2 className="text-2xl font-bold flex-grow flex items-center justify-center">
          Müşteri Yönetimi <FaUsers className="ml-2" />
        </h2>
      </div>

      {/* {error && <p className="text-center text-red-500">{error}</p>} */}

      {loading ? (
        <p className="text-center text-gray-500 font-semibold">
          Müşteri Listesi Yükleniyor...
        </p>
      ) : customers && customers.length > 0 ? (
        <div className="overflow-x-auto">
          <div className="flex items-center mb-4">
            {/* Arama Kutusu */}
            <input
              type="text"
              placeholder="Ad, Soyad, Telefon ve Email ile ara"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-2/3 p-2 border rounded-md"
            />

            {/* İndirme Butonu */}
            <div className="ml-auto">
              <button
                onClick={handleExport}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
              >
                Excel İndir
              </button>
            </div>
          </div>

          {/* Müşteri Tablosu */}
          {customers && customers.length > 0 ? (
            <>
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr className="text-left">
                    <th className="py-2 pl-4 pr-2 md:px-4 border-b">Ad</th>
                    <th className="py-2 px-2 md:px-4 border-b">Soyad</th>
                    <th className="py-2 px-2 md:px-4 border-b">Telefon</th>
                    <th className="py-2 px-2 md:px-4 border-b">Email</th>
                    <th className="py-2 px-2 md:px-4 border-b">İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {currentCustomers.map((customer) => (
                    <tr key={customer._id} className="hover:bg-gray-100">
                      <td className="py-2 pl-4 pr-2 md:px-4 border-b">
                        {customer.firstName}
                      </td>
                      <td className="py-2 px-2 md:px-4 border-b">
                        {customer.lastName}
                      </td>
                      <td className="py-2 px-2 md:px-4 border-b">
                        {customer.phone}
                      </td>
                      <td className="py-2 px-2 md:px-4 border-b">
                        {customer.email}
                      </td>
                      <td className="py-2 px-2 md:px-4 border-b flex flex-col md:flex-row">
                        <button
                          onClick={() => handleEditClick(customer)}
                          className="bg-blue-500 text-white px-2 md:px-4 py-2 rounded-md mb-2 md:mb-0 md:mr-2 hover:bg-blue-600"
                        >
                          Güncelle
                        </button>
                        <button
                          onClick={() => handleDeleteCustomer(customer._id)}
                          className="bg-red-500 text-white px-2 md:px-4 py-2 rounded-md hover:bg-red-600"
                        >
                          Sil
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Sayfalama Kontrolleri */}
              <div className="flex justify-center space-x-4 mt-4 pb-4 md:pb-0">
                <button
                  onClick={handleFirstPage}
                  disabled={currentPage === 1}
                  className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
                >
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="m17 16-4-4 4-4m-6 8-4-4 4-4"
                    />
                  </svg>
                  {/* İlk */}
                </button>

                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
                >
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="m14 8-4 4 4 4"
                    />
                  </svg>
                  {/* Önceki */}
                </button>

                <span className="py-1 px-3 text-gray-500">
                  {currentPage} / {totalPages}
                </span>

                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
                >
                  {/* Sonraki */}
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="m10 16 4-4-4-4"
                    />
                  </svg>
                </button>

                <button
                  onClick={handleLastPage}
                  disabled={currentPage === totalPages}
                  className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-lg"
                >
                  {/* Son */}
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="m7 16 4-4-4-4m6 8 4-4-4-4"
                    />
                  </svg>
                </button>
              </div>
            </>
          ) : (
            <p className="text-center text-gray-500 font-semibold">
              Hiç müşteri bulunamadı.
            </p>
          )}

          {editingCustomerId && (
            <div>
              <h3 className="text-xl font-semibold mt-6 mb-4 text-center">
                Müşteri Bilgilerini Güncelle
              </h3>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleUpdateCustomer(editingCustomerId)
                }}
                className="space-y-4"
              >
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="İsim"
                  required
                  className="w-full px-4 py-2 border rounded-md"
                />
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="Soyisim"
                  required
                  className="w-full px-4 py-2 border rounded-md"
                />
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Telefon"
                  required
                  className="w-full px-4 py-2 border rounded-md"
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  className="w-full px-4 py-2 border rounded-md"
                />
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="Şifre"
                  required={false}
                  className="w-full px-4 py-2 border rounded-md"
                />
                <div className="flex justify-center pt-2 pb-4 space-x-4">
                  <button
                    type="button"
                    onClick={clearForm}
                    className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                  >
                    Formu Kapat
                  </button>

                  <button
                    type="submit"
                    className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                  >
                    Müşteriyi Güncelle
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      ) : (
        <p className="text-center text-gray-500 font-semibold">
          Hiç müşteri bulunamadı.
        </p>
      )}
    </div>
  )
}

export default CustomerManagement
