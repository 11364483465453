import axiosInstance from '../utils/axiosInstance'

import {
  GET_CLOSED_SLOTS_REQUEST,
  GET_CLOSED_SLOTS_SUCCESS,
  GET_CLOSED_SLOTS_FAILURE,
  CLOSE_APPOINTMENTS_REQUEST,
  CLOSE_APPOINTMENTS_SUCCESS,
  CLOSE_APPOINTMENTS_FAILURE,
  OPEN_APPOINTMENTS_REQUEST,
  OPEN_APPOINTMENTS_SUCCESS,
  OPEN_APPOINTMENTS_FAILURE
} from '../types/closedTypes'

export const getClosedSlots = (employeeId) => async (dispatch) => {
  try {
    dispatch({ type: GET_CLOSED_SLOTS_REQUEST })

    const { data } = await axiosInstance.post('/closedSlots/getClosedSlots', {
      employee: employeeId
    })

    dispatch({ type: GET_CLOSED_SLOTS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: GET_CLOSED_SLOTS_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const closeAppointments = (slotData) => async (dispatch) => {
  try {
    dispatch({ type: CLOSE_APPOINTMENTS_REQUEST })

    const { data } = await axiosInstance.post('/closedSlots/close', slotData)

    dispatch({ type: CLOSE_APPOINTMENTS_SUCCESS, payload: data })

    dispatch(getClosedSlots())

    return data
  } catch (error) {
    dispatch({
      type: CLOSE_APPOINTMENTS_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
    throw error
  }
}

export const openAppointments = (slotData) => async (dispatch) => {
  try {
    dispatch({ type: OPEN_APPOINTMENTS_REQUEST })

    const { data } = await axiosInstance.post('/closedSlots/open', slotData)

    dispatch({ type: OPEN_APPOINTMENTS_SUCCESS, payload: data })

    dispatch(getClosedSlots())

    return data
  } catch (error) {
    dispatch({
      type: OPEN_APPOINTMENTS_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
    throw error
  }
}
