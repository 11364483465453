import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployees } from '../actions/employeeActions'
import toast from 'react-hot-toast'
import { IoDiamond } from 'react-icons/io5'

const EmployeeSelection = ({
  selectedEmployee,
  setSelectedEmployee,
  setSelectedService,
  setActiveTab
}) => {
  const dispatch = useDispatch()
  const { employees } = useSelector((state) => state.employeeReducer || {})

  useEffect(() => {
    dispatch(getEmployees())
  }, [dispatch])

  const handleEmployeeSelect = (employee) => {
    setSelectedEmployee(employee)
    setSelectedService(null)
  }

  const handleNext = () => {
    if (selectedEmployee) {
      setActiveTab('serviceSelection')
    } else {
      toast.error('Lütfen önce bir çalışan seçin.')
    }
  }

  return (
    <div className="p-4">
      {/* <h2 className="text-2xl font-bold text-center mb-6 text-orange-400">
        Çalışan Seçimi
      </h2> */}

      {/* Üstteki ileri butonu */}
      <div className="relative flex items-center mb-6">
        <h2 className="absolute left-1/2 transform -translate-x-1/2 text-2xl font-bold text-orange-400">
          Çalışan Seçimi
        </h2>

        <div className="flex-grow"></div>

        <button
          onClick={handleNext}
          className="flex bg-blue-500 text-white px-3 md:px-6 py-2 rounded-lg hover:bg-blue-600 font-semibold ml-auto mr-1"
        >
          İleri
          <svg
            className="ml-1 w-6 h-6 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 12H5m14 0-4 4m4-4-4-4"
            />
          </svg>
        </button>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
        {employees.map((employee) => (
          <div
            key={employee._id}
            className={`border-4 p-4 rounded-2xl transition-all duration-300 ease-in-out relative bg-[#27272a] h-64 flex flex-col justify-end items-center ${
              selectedEmployee?._id === employee._id
                ? 'border-orange-500 shadow-lg'
                : 'border-gray-200'
            }`}
            style={{
              backgroundImage: `url(${employee.photoUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            {/* Ad Soyad kısmı */}
            {/* <p className="relative text-center text-cyan-800 mb-4 font-black text-xl">
              {employee.firstName} {employee.lastName}{' '}
              {employee.vip && (
                <span className="text-yellow-500 font-redrose">+VIP</span>
              )}
            </p> */}

            {/* Ad Soyad kısmı */}
            <p
              className="relative text-center mb-3 font-black text-xl"
              style={{
                color: '#ffffff',
                WebkitTextStroke: '.7px #9a3412',
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
                letterSpacing: '0.05em'
              }}
            >
              {employee.firstName} {employee.lastName}{' '}
              {employee.vip && (
                <span className="text-yellow-500 font-redrose flex justify-center mt-1 items-center">
                  <IoDiamond className="text-xl text-gray-50 mr-1" /> +VIP
                </span>
              )}
            </p>

            {/* Seçme Butonu */}
            <button
              onClick={() => handleEmployeeSelect(employee)}
              className={`relative w-full py-2 rounded-md transition-colors duration-200 ease-in-out font-semibold opacity-85 ${
                selectedEmployee?._id === employee._id
                  ? 'bg-orange-500 text-white cursor-default'
                  : 'bg-gray-300 text-gray-800 hover:bg-blue-300 hover:text-gray-900'
              }`}
            >
              {selectedEmployee?._id === employee._id ? 'Seçildi' : 'Seç'}
            </button>
          </div>
        ))}
      </div>

      <div className="mt-6 flex justify-end">
        <button
          onClick={handleNext}
          className="flex bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 font-semibold"
        >
          İleri
          <svg
            className="ml-1 w-6 h-6 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 12H5m14 0-4 4m4-4-4-4"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default EmployeeSelection
