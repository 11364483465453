import axiosInstance from '../utils/axiosInstance'
import toast from 'react-hot-toast'

export const sendContactMessage = async ({ email, subject, message }) => {
  try {
    await axiosInstance.post('/contact', {
      email,
      subject,
      message
    })
    toast.success('Mesajınız başarıyla gönderildi!')
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Mesaj gönderilirken bir hata oluştu.'
    toast.error(message)
  }
}
