import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getClosedSlots,
  closeAppointments,
  openAppointments
} from '../actions/closedActions'
import EmployeeCalendar from './EmployeeCalendar'

const EmployeeAppointmentSettings = () => {
  const { closedSlots = [], loading = false } = useSelector(
    (state) => state.closedReducer || {}
  )

  const sortedClosedSlots = [...closedSlots].sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  )

  return (
    <div>
      <h3 className="font-semibold text-center mb-2 px-4">
        Randevuya Kapalı Gün ve Saatleri Ayarlama Takvimi
      </h3>
      <EmployeeCalendar />

      <div className="p-4 bg-white shadow-md rounded-2xl mt-4">
        <h4 className="text-lg font-semibold mb-4">
          Kapatılan Zamanlar Liste:
        </h4>
        {sortedClosedSlots.length > 0 ? (
          <div className="space-y-4">
            {sortedClosedSlots.map((slot) => (
              <div
                key={slot._id}
                className="p-4 border rounded-xl bg-gray-50 text-sm"
              >
                <p className="text-gray-700">
                  <span className="font-bold">
                    {slot.fullDay ? 'Tüm Gün' : slot.timeSlots.join(', ')}
                  </span>
                  {' - '}
                  {new Date(slot.date).toLocaleDateString()}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500">Kapatılan zaman dilimi yok.</p>
        )}
      </div>
    </div>
  )
}

export default React.memo(EmployeeAppointmentSettings)
