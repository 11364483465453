import React from 'react'

const HairStylesGrid = () => {
  const styles = [
    {
      name: 'FADE CUT',
      image: 'hair-1.webp'
    },
    {
      name: 'CREW CUT',
      image: 'hair-2.webp'
    },
    {
      name: 'TAPPER FADE',
      image: 'hair-3.webp'
    },
    {
      name: 'SKIN FADE',
      image: 'hair-4.webp'
    },
    {
      name: 'BURST FADE',
      image: 'hair-5.webp'
    },
    {
      name: 'LOW FADE',
      image: 'hair-6.webp'
    },
    {
      name: 'MID FADE',
      image: 'hair-7.webp'
    },
    {
      name: 'HIGH FADE',
      image: 'hair-8.webp'
    },
    {
      name: 'SHADOW FADE',
      image: 'hair-9.webp'
    },
    {
      name: 'ITALIAN CUT',
      image: 'hair-10.webp'
    },
    {
      name: 'FRENCH CUT',
      image: 'hair-11.webp'
    },
    {
      name: 'MULLER CUT',
      image: 'hair-12.webp'
    }
  ]

  return (
    <div className="min-h-screen flex items-center justify-center shadow-2xl rounded-3xl">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 max-w-8xl p-12 rounded-3xl shadow-custom-equal-2">
        {styles.map((style, index) => (
          <div
            key={index}
            className="relative bg-zinc-800 p-4 shadow-custom-equal overflow-hidden transform hover:scale-105 transition-transform duration-300 rounded-3xl"
          >
            <img
              src={style.image}
              alt={style.name}
              className="w-full h-72 object-cover rounded-xl mb-4"
            />
            <div className="absolute bottom-0 left-0 right-0 px-4 py-5 bg-zinc-800 bg-opacity-80 shadow-xl">
              <h4 className=" text-shadow-outline text-lg font-semibold tracking-wide text-center text-neutral-50 drop-shadow-xl">
                {style.name}
              </h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default HairStylesGrid
