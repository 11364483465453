import { jwtDecode } from 'jwt-decode'

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_MESSAGE,
  REMOVE_MESSAGE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE
} from '../types/authTypes'

const initialState = {
  loading: false,
  isAuthenticated: false,
  token: null,
  message: '',
  user: {}
}

const verifyToken = (token) => {
  try {
    const decodedToken = jwtDecode(token)

    const expiresIn = new Date(decodedToken.exp * 1000)

    if (new Date() > expiresIn) {
      localStorage.removeItem('myToken')
      return null
    } else {
      return decodedToken
    }
  } catch (error) {
    localStorage.removeItem('myToken')
    return null
  }
}

const token = localStorage.getItem('myToken')
if (token) {
  const decoded = verifyToken(token)

  if (decoded) {
    initialState.token = token
    const { user } = decoded
    initialState.user = user
    initialState.isAuthenticated = true
  }
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case REGISTER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      const decodedUser = verifyToken(action.payload)
      return {
        ...state,
        loading: false,
        isAuthenticated: !!decodedUser,
        token: decodedUser ? action.payload : null,
        user: decodedUser ? decodedUser.user : {}
      }
    case REGISTER_FAILURE:
    case LOGIN_FAILURE:
    case LOGOUT:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        token: null,
        user: {},
        message: action.payload
      }
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload
      }
    case REMOVE_MESSAGE:
      return {
        ...state,
        message: ''
      }
    default:
      return state
  }
}

export default authReducer
