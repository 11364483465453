import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { resetPassword } from '../actions/authActions'
import { REMOVE_MESSAGE } from '../types/authTypes'
import toast from 'react-hot-toast'

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { token } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { message = [] } = useSelector((state) => state.authReducer)

  const handleSubmit = (e) => {
    e.preventDefault()

    let trimmedPassword = newPassword.trim()
    let trimmedConfirmPassword = confirmPassword.trim()

    if (!trimmedPassword || !trimmedConfirmPassword) {
      return toast.error('Yeni Şifre alanları boş bırakılamaz!')
    }

    if (trimmedPassword.length < 6) {
      return toast.error('Yeni Şifre en az 6 karakter olmalıdır.')
    }

    if (trimmedPassword !== trimmedConfirmPassword) {
      return toast.error('Şifreler uyuşmuyor!')
    }

    dispatch(resetPassword({ token, newPassword: trimmedPassword }))

    setTimeout(() => {
      navigate('/login')
    }, 1500)
  }

  useEffect(() => {
    const messagesArray = Array.isArray(message) ? message : [message]
    if (message.length > 0) {
      messagesArray.map((msg) => toast(msg, { icon: 'ℹ️' }))
    }
    dispatch({ type: REMOVE_MESSAGE })
  }, [dispatch, message])

  return (
    <div className="flex items-center justify-center p-6 md:p-20 min-h-[calc(100vh-200px)] bg-zinc-800">
      <form
        onSubmit={handleSubmit}
        className="p-8 w-full max-w-md bg-zinc-800 rounded-lg shadow-md outline outline-1 outline-charcoal"
      >
        <h2 className="text-2xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-cyan-300 text-center">
          Yeni Şifreni Oluştur
        </h2>

        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Yeni Şifre"
          className="w-full mb-4 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
        />

        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Yeni Şifre Tekrar"
          className="w-full mb-4 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
        />

        <button
          type="submit"
          className="w-full px-4 py-2 rounded-lg font-semibold text-neutral-100 bg-teal-600 hover:bg-teal-500"
        >
          Şifremi Güncelle
        </button>
      </form>
    </div>
  )
}

export default ResetPasswordPage
