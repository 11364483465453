import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  getAppointments,
  cancelAppointment,
  deleteAppointment
} from '../actions/Admin/adminAppointmentActions'
import {
  getEmployees,
  addEmployee,
  updateEmployee,
  deleteEmployee
} from '../actions/Admin/adminEmployeeActions'
import {
  getCustomers,
  updateCustomer,
  deleteCustomer
} from '../actions/Admin/adminCustomerActions'
import {
  addService,
  updateService,
  deleteService
} from '../actions/Admin/adminServiceActions'

import { MdAdminPanelSettings } from 'react-icons/md'
import { GiMustache } from 'react-icons/gi'
import { FaUsers, FaCalendarAlt } from 'react-icons/fa'
import { PiOfficeChairFill } from 'react-icons/pi'
import { IoDiamond } from 'react-icons/io5'

const AdminDashboard = () => {
  return (
    <div className="">
      <div className="max-w-2xl mx-auto p-4">
        <div className="flex items-center justify-center">
          <h1 className="text-3xl font-semibold font-redrose text-center my-4">
            Admin Paneli
          </h1>
          <MdAdminPanelSettings className="text-3xl ml-2" />
        </div>

        <div className="flex justify-center space-x-4 mb-2">
          <Link
            to="/admin-dashboard/employees"
            className="py-10 px-4 md:py-20 md:px-10 rounded-3xl text-base md:text-xl transition duration-200 bg-zinc-800 hover:bg-zinc-600 text-white text-center flex flex-col items-center"
          >
            <GiMustache className="text-5xl md:text-8xl mb-4" />
            <p className="font-redrose tracking-wider">Çalışan Yönetimi</p>
          </Link>
          <Link
            to="/admin-dashboard/customers"
            className="py-10 px-4 md:py-20 md:px-10 rounded-3xl text-base md:text-xl transition duration-200 bg-zinc-800 hover:bg-zinc-600 text-white text-center flex flex-col items-center"
          >
            <FaUsers className="text-5xl md:text-8xl mb-4" />
            <p className="font-redrose tracking-wider">Müşteri Yönetimi</p>
          </Link>
          <Link
            to="/admin-dashboard/appointments"
            className="py-10 px-4 md:py-20 md:px-10 rounded-3xl text-base md:text-xl transition duration-200 bg-zinc-800 hover:bg-zinc-600 text-white text-center flex flex-col items-center"
          >
            <FaCalendarAlt className="text-5xl md:text-8xl mb-4" />
            <p className="font-redrose tracking-wider">Randevu Yönetimi</p>
          </Link>
        </div>

        <div className="flex space-x-8">
          <div className="flex-1 mt-4">
            <Link
              to="/admin-dashboard/services"
              className="py-5 px-4 md:py-10 md:px-10 rounded-3xl text-base md:text-xl transition duration-200 bg-zinc-800 hover:bg-zinc-600 text-white text-center flex items-center justify-center"
            >
              <PiOfficeChairFill className="text-4xl md:text-5xl mr-4" />
              <p className="font-redrose tracking-wider">Hizmetler</p>
            </Link>
          </div>

          <div className="flex-1 mt-4">
            <Link
              to="/admin-dashboard/vip-services"
              className="py-5 px-4 md:py-10 md:px-10 rounded-3xl text-base md:text-xl transition duration-200 bg-zinc-800 hover:bg-zinc-600 text-white text-center flex items-center justify-center"
            >
              <IoDiamond className="text-4xl md:text-5xl mr-4" />
              <p className="font-redrose tracking-wider">VIP Hizmetler</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard
