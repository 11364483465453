import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, user } = useSelector((state) => state.authReducer)
  const location = useLocation()

  const isCustomer = user?.role === 'Customer'
  const isEmployee = user?.role === 'Employee'
  const isAdmin = user?.role === 'Admin'

  if (isAuthenticated) {
    if (
      isCustomer &&
      (location.pathname === '/employee-dashboard' ||
        location.pathname === '/admin-dashboard')
    ) {
      return <Navigate to="/customer-dashboard" replace />
    }
    if (
      isEmployee &&
      (location.pathname === '/customer-dashboard' ||
        location.pathname === '/admin-dashboard')
    ) {
      return <Navigate to="/employee-dashboard" replace />
    }
    if (
      isAdmin &&
      (location.pathname === '/customer-dashboard' ||
        location.pathname === '/employee-dashboard')
    ) {
      return <Navigate to="/admin-dashboard" replace />
    }
    return children
  }

  return <Navigate to="/login" replace />
}

export default PrivateRoute
