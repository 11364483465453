import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllAppointments,
  createAppointment
} from '../actions/appointmentActions'
import { getClosedSlots } from '../actions/closedActions'
import { format, addMonths, subMonths } from 'date-fns'
import { tr } from 'date-fns/locale'
import LoadingSpinner from './LoadingSpinner'
import toast from 'react-hot-toast'

const Calendar = ({ selectedEmployee, selectedService, setActiveTab }) => {
  const dispatch = useDispatch()
  const { appointments = [], loading = false } = useSelector(
    (state) => state.appointmentReducer || {}
  )

  const { user } = useSelector((state) => state.authReducer)
  const { closedSlots = [] } = useSelector((state) => state.closedReducer || {})
  const [currentDate, setCurrentDate] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState(null)

  const [selectedTimeSlot, setSelectedTimeSlot] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    dispatch(getAllAppointments())

    if (selectedEmployee) {
      dispatch(getClosedSlots(selectedEmployee))
    }
  }, [dispatch, selectedEmployee])

  const timeSlots = [
    '10:00-11:00',
    '11:00-12:00',
    '12:00-13:00',
    '13:00-14:00',
    '14:00-15:00',
    '15:00-16:00',
    '16:00-17:00',
    '17:00-18:00',
    '18:00-19:00',
    '19:00-20:00',
    '20:00-21:00',
    '21:00-22:00',
    '22:00-23:00'
  ]

  const handlePrevMonth = () => setCurrentDate(subMonths(currentDate, 1))
  const handleNextMonth = () => setCurrentDate(addMonths(currentDate, 1))

  const closeModal = () => {
    setIsModalOpen(false)
    setSelectedTimeSlot('')
    document.activeElement.blur()
  }

  const handleDayClick = (day, dolulukOrani) => {
    if (!selectedEmployee) return

    const selectedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    )
    const weekDay = selectedDate.getDay()

    if (dolulukOrani === 100 || weekDay === 0) return

    setSelectedDate(selectedDate)
    setIsModalOpen(true)
  }

  const handleTimeSlotClick = (slot) => {
    setSelectedTimeSlot(slot)
  }

  const handleCreateAppointment = () => {
    if (!user || !selectedDate || !selectedEmployee || !selectedTimeSlot) {
      if (!selectedTimeSlot) {
        toast.error('Randevu Saatinizi Seçiniz!')
      } else {
        toast.error('Lütfen tüm bilgileri seçiniz!')
      }
      return
    }

    const formattedDate = format(selectedDate, 'yyyy-MM-dd')

    dispatch(
      createAppointment({
        customer: user._id,
        employee: selectedEmployee,
        service: selectedService._id,
        date: formattedDate,
        timeSlot: selectedTimeSlot
      })
    )
      .then(() => {
        closeModal()
      })
      .catch((err) => {})
  }

  const renderAppointmentsForDay = (day) => {
    const dayAppointments = appointments.filter(
      (appointment) =>
        new Date(appointment.date).getDate() === day &&
        new Date(appointment.date).getMonth() === currentDate.getMonth() &&
        new Date(appointment.date).getFullYear() ===
          currentDate.getFullYear() &&
        appointment.employee === selectedEmployee
    )

    const doluRandevuSayisi = dayAppointments.length

    const dayCloseds = closedSlots.filter(
      (closedslot) =>
        new Date(closedslot.date).getDate() === day &&
        new Date(closedslot.date).getMonth() === currentDate.getMonth() &&
        new Date(closedslot.date).getFullYear() === currentDate.getFullYear() &&
        closedslot.employee === selectedEmployee
    )

    const randevuyaKapaliSayisi = dayCloseds[0]?.timeSlots
      ? dayCloseds[0]?.timeSlots.length
      : dayCloseds

    const isFullDayClosed = dayCloseds ? dayCloseds[0]?.fullDay : false

    const toplamDoluVeKapali = doluRandevuSayisi + randevuyaKapaliSayisi
    const dolulukOrani = isFullDayClosed
      ? 100
      : (toplamDoluVeKapali / timeSlots.length) * 100

    const dayDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    )
    const weekDay = dayDate.getDay()
    const isSunday = weekDay === 0

    return (
      <div
        key={day}
        onClick={() => handleDayClick(day, dolulukOrani)}
        className="py-3 md:p-4 border rounded-lg cursor-pointer day-box transition-colors duration-300 ease-in-out"
        style={{
          '--dolulukOrani': `${dolulukOrani}%`,
          '--opacity': isSunday || !selectedEmployee ? 0.5 : 1,
          '--pointer-events': selectedEmployee ? 'auto' : 'none',
          cursor: isSunday || isFullDayClosed ? 'not-allowed' : 'pointer'
        }}
      >
        <span className="font-bold text-gray-800">{day}</span>
      </div>
    )
  }

  const renderCalendarDays = () => {
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    )
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    )
    const daysInMonth = Array.from(
      { length: endOfMonth.getDate() },
      (_, i) => i + 1
    )

    const startDayOfWeek = (startOfMonth.getDay() + 6) % 7

    const emptyDays = Array.from({ length: startDayOfWeek }, (_, i) => (
      <div key={`empty-${i}`} className="p-4" />
    ))

    const days = daysInMonth.map(renderAppointmentsForDay)

    return [...emptyDays, ...days]
  }

  const renderTimeSlots = () => {
    const dayAppointments = appointments.filter(
      (appointment) =>
        new Date(appointment.date).getDate() === selectedDate.getDate() &&
        new Date(appointment.date).getMonth() === selectedDate.getMonth() &&
        new Date(appointment.date).getFullYear() ===
          selectedDate.getFullYear() &&
        appointment.employee === selectedEmployee
    )

    const dayCloseds = closedSlots.filter(
      (closedslot) =>
        new Date(closedslot.date).getDate() === selectedDate.getDate() &&
        new Date(closedslot.date).getMonth() === selectedDate.getMonth() &&
        new Date(closedslot.date).getFullYear() ===
          selectedDate.getFullYear() &&
        closedslot.employee === selectedEmployee
    )

    const closedTimeSlots = dayCloseds[0]?.timeSlots || []

    return (
      <div className="grid grid-cols-2 gap-4 mt-4 md:grid-cols-4">
        {timeSlots.map((slot) => {
          const isSlotBooked = dayAppointments.some(
            (appointment) => appointment.timeSlot === slot
          )
          const isSlotClosed = closedTimeSlots.includes(slot)
          const isSelected = slot === selectedTimeSlot
          return (
            <div
              key={slot}
              onClick={() => handleTimeSlotClick(slot)}
              className={`p-2 border rounded-t-3xl text-center cursor-pointer transition-colors duration-300 ease-in-out font-semibold ${
                isSlotBooked
                  ? 'bg-red-600 text-white pointer-events-none'
                  : isSlotClosed
                  ? 'bg-red-600 text-white pointer-events-none'
                  : isSelected
                  ? 'bg-blue-500 text-white'
                  : 'bg-green-500 text-white'
              } hover:bg-blue-300`}
            >
              {slot}
            </div>
          )
        })}
      </div>
    )
  }

  if (loading) {
    return (
      <div className="font-medium pl-4">
        Yükleniyor... <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className="max-w-4xl mx-auto px-2 md:p-6 bg-gray-50 rounded-3xl shadow-md">
      {/* <div className="pt-2 md:pt-0 flex justify-center">
        <select
          value={selectedEmployee}
          onChange={(e) => setSelectedEmployee(e.target.value)}
          required
          className="mt-2 mb-4 md:mb-6 block w-8/12 md:w-6/12 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-75 focus:border-indigo-500 transition duration-300 ease-in-out transform md:hover:scale-105"
        >
          <option value="" disabled>
            👥 Bir Çalışan Seçiniz
          </option>
          {employees.length > 0 ? (
            employees.map((employee) => (
              <option key={employee._id} value={employee._id}>
                {employee.firstName} {employee.lastName}
              </option>
            ))
          ) : (
            <option value="" disabled>
              Çalışan bulunamadı
            </option>
          )}
        </select>
      </div> */}

      <div className="flex justify-between mb-6 font-semibold space-x-8 pt-4 md:pt-0">
        <button
          onClick={() => setActiveTab('employeeSelection')}
          className="bg-orange-300 hover:bg-orange-400 text-gray-900 px-2 md:px-4 py-2 rounded-xl"
        >
          👥Çalışan Seçimine Geri Dön
        </button>
        <button
          onClick={() => setActiveTab('serviceSelection')}
          className="bg-blue-300 hover:bg-blue-400 text-gray-900 px-2 md:px-4 py-2 rounded-xl"
        >
          🧔🏻Hizmet Seçimine Geri Dön
        </button>
      </div>

      <div className="flex justify-between items-center mb-6 mx-6">
        <button
          onClick={handlePrevMonth}
          className="text-2xl font-bold text-gray-700 hover:text-gray-900"
        >
          &lt;
        </button>
        <h2 className="text-3xl font-semibold">
          {format(currentDate, 'MMMM yyyy', { locale: tr })}
        </h2>
        <button
          onClick={handleNextMonth}
          className="text-2xl font-bold text-gray-700 hover:text-gray-900"
        >
          &gt;
        </button>
      </div>

      <div className="grid grid-cols-7 gap-x-2 gap-y-3 md:gap-4 mb-4 text-center font-semibold">
        {['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'].map((day) => (
          <div key={day} className="">
            {day}
          </div>
        ))}
        {renderCalendarDays()}
      </div>

      {/* Takvimin altındaki mesajlar */}
      {!selectedEmployee ? (
        <p className="text-center font-bold text-amber-600 pb-4 md:pb-0">
          Dolu ve Boş Randevu Saatlerini Görebilmek İçin Önce Çalışan, Sonra
          Hizmet Seçiniz
        </p>
      ) : (
        <p className="text-center font-bold text-green-600 pb-4 md:pb-0">
          Gün Seçimi Yapınız
        </p>
      )}

      {isModalOpen && (
        <div className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-3xl shadow-lg max-w-sm md:max-w-xl w-full">
            <h2 className="text-lg font-semibold mb-4 text-center">
              Randevu Al: {format(selectedDate, 'PPPP', { locale: tr })}
            </h2>
            {renderTimeSlots()}
            <div className="mt-4 flex justify-end font-semibold">
              <button
                className="bg-gray-500 hover:bg-gray-600 text-white p-4 rounded-xl mr-8"
                onClick={closeModal}
              >
                İptal
              </button>
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white p-4 rounded-xl"
                onClick={handleCreateAppointment}
              >
                Onayla
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Calendar
