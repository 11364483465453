import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import {
  getClosedSlots,
  closeAppointments,
  openAppointments
} from '../actions/closedActions'
import { format, addMonths, subMonths } from 'date-fns'
import { tr } from 'date-fns/locale'
import moment from 'moment-timezone'
import toast from 'react-hot-toast'

const selectClosedSlots = createSelector(
  (state) => state.closedReducer.closedSlots,
  (closedSlots) => (closedSlots.length ? closedSlots : [])
)

const EmployeeCalendar = () => {
  const dispatch = useDispatch()

  const closedSlots = useSelector(selectClosedSlots)

  const employee = useSelector((state) => state.authReducer?.user?._id || null)

  const [currentDate, setCurrentDate] = useState(new Date())

  const [selectedDate, setSelectedDate] = useState(null)

  const [selectedTimeSlots, setSelectedTimeSlots] = useState([])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [selectFullDay, setSelectFullDay] = useState(false)

  const timeSlots = [
    '10:00-11:00',
    '11:00-12:00',
    '12:00-13:00',
    '13:00-14:00',
    '14:00-15:00',
    '15:00-16:00',
    '16:00-17:00',
    '17:00-18:00',
    '18:00-19:00',
    '19:00-20:00',
    '20:00-21:00',
    '21:00-22:00',
    '22:00-23:00'
  ]

  const [refreshKey, setRefreshKey] = useState(0)

  const memoizedClosedSlots = useMemo(
    () => JSON.stringify(closedSlots),
    [closedSlots]
  )

  const fetchClosedSlots = useCallback(() => {
    if (employee) {
      dispatch(getClosedSlots(employee))
    }
  }, [dispatch, employee])

  useEffect(() => {
    if (employee) {
      fetchClosedSlots()
    }
  }, [dispatch, employee, refreshKey, fetchClosedSlots, memoizedClosedSlots])

  const handleDayClick = (day) => {
    setSelectedDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
    )
    const closedDay = closedSlots.find(
      (slot) =>
        new Date(slot.date).toDateString() ===
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          day
        ).toDateString()
    )

    if (closedDay) {
      setSelectedTimeSlots(closedDay.timeSlots)
      setSelectFullDay(closedDay.fullDay)
    } else {
      setSelectedTimeSlots([])
      setSelectFullDay(false)
    }
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedTimeSlots([])
    setSelectFullDay(false)
  }

  const handleTimeSlotChange = (slot) => {
    setSelectedTimeSlots((prev) =>
      prev.includes(slot) ? prev.filter((s) => s !== slot) : [...prev, slot]
    )
  }

  const handleFullDayChange = () => {
    setSelectFullDay(!selectFullDay)
    setSelectedTimeSlots([])
  }

  const handleCloseAppointments = async () => {
    try {
      if (!selectedDate || (!selectFullDay && selectedTimeSlots.length === 0)) {
        toast.error('Lütfen bir tarih ve zaman dilimi seçin!')
        return
      }

      const formattedDate = moment
        .tz(selectedDate, 'Europe/Istanbul')
        .format('YYYY-MM-DD')

      const res = await dispatch(
        closeAppointments({
          date: formattedDate,
          timeSlots: selectFullDay ? [] : selectedTimeSlots,
          fullDay: selectFullDay
        })
      )

      toast.success(res.message || 'Randevular başarıyla kapatıldı!')

      setRefreshKey((prev) => prev + 1)
      handleCloseModal()
    } catch (error) {
      if (error.message) {
        toast.error(`Hata: ${error.response.data.message}`)
      } else if (error.request) {
        toast.error('Ağ hatası, lütfen tekrar deneyin.')
      } else {
        toast.error(
          `Hata: ${
            error.message || 'Randevuları kapatma işlemi başarısız oldu!'
          } `
        )
      }
    }
  }

  const handleOpenAppointments = async () => {
    try {
      const formattedDate = moment
        .tz(selectedDate, 'Europe/Istanbul')
        .format('YYYY-MM-DD')

      const closedDay = closedSlots.find(
        (slot) =>
          new Date(slot.date).toDateString() === selectedDate.toDateString()
      )

      if (!closedDay) {
        toast.error('Bu tarihte kapatılan randevu saatleri yok.')
        return
      }

      if (selectedTimeSlots.length === 0 && !selectFullDay) {
        const res = await dispatch(
          openAppointments({ date: formattedDate, timeSlots: [] })
        )
        toast.success(res.message || 'Gün tamamen açıldı!')
      } else {
        const res = await dispatch(
          openAppointments({
            date: formattedDate,
            timeSlots: selectedTimeSlots,
            fullDay: false
          })
        )
        toast.success(res.message || 'Seçilen saatler başarıyla açıldı!')
      }

      dispatch(getClosedSlots(employee))
      setRefreshKey((prev) => prev + 1)
      handleCloseModal()
    } catch (error) {
      toast.error(error.message || 'Randevuları açma işlemi başarısız oldu!')
    }
  }

  const renderDay = (day) => {
    const selectedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    )
    const closedDay = closedSlots.find(
      (closedSlot) =>
        new Date(closedSlot.date).toDateString() === selectedDate.toDateString()
    )
    const closedSlotCount = closedDay ? closedDay.timeSlots.length : 0
    const totalSlots = timeSlots.length
    const kapalilikOrani =
      closedDay && closedDay.fullDay
        ? 100
        : (closedSlotCount / totalSlots) * 100

    return (
      <div
        key={day}
        onClick={() => handleDayClick(day)}
        className="py-3 md:p-4 border rounded-lg cursor-pointer day-box transition-colors duration-300 ease-in-out"
        style={{
          backgroundColor: '#FFFFFF',
          backgroundImage: `linear-gradient(to top, rgba(255, 0, 0, 0.5) ${kapalilikOrani}%, transparent ${kapalilikOrani}%)`,
          '--kapalilikOrani': `${kapalilikOrani}%`
        }}
      >
        <span className="font-bold text-gray-800">{day}</span>
      </div>
    )
  }

  const renderCalendarDays = () => {
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    )

    const startDay = (startOfMonth.getDay() + 6) % 7

    const daysInMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate()

    const calendarDays = []

    for (let i = 0; i < startDay; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="p-4" />)
    }

    for (let day = 1; day <= daysInMonth; day++) {
      calendarDays.push(renderDay(day))
    }

    return calendarDays
  }

  const renderTimeSlots = () => {
    const closedDay = closedSlots.find(
      (slot) =>
        new Date(slot.date).toDateString() === selectedDate.toDateString()
    )

    const isFullDayClosed = closedDay ? closedDay.fullDay : false

    return (
      <div className="grid grid-cols-2 gap-4 mt-4 md:grid-cols-4">
        {timeSlots.map((slot) => {
          const isSlotClosed =
            isFullDayClosed ||
            (closedDay ? closedDay.timeSlots.includes(slot) : false)
          return (
            <div
              key={slot}
              onClick={() => handleTimeSlotChange(slot)}
              className={`p-2 border rounded-t-3xl text-center cursor-pointer transition-colors duration-300 ease-in-out ${
                selectedTimeSlots.includes(slot)
                  ? 'bg-blue-500 text-white'
                  : isSlotClosed
                  ? 'bg-red-500 text-white'
                  : 'bg-gray-300 text-black'
              } hover:bg-blue-300`}
            >
              {slot}
            </div>
          )
        })}
      </div>
    )
  }

  const handlePrevMonth = () => setCurrentDate(subMonths(currentDate, 1))
  const handleNextMonth = () => setCurrentDate(addMonths(currentDate, 1))

  return (
    <div className="max-w-4xl mx-auto px-2 md:p-6 bg-gray-50 rounded-3xl shadow-md">
      <div className="flex justify-between items-center mb-6 mx-6 pt-6 md:pt-2">
        <button
          onClick={handlePrevMonth}
          className="text-2xl font-bold text-gray-700 hover:text-gray-900"
        >
          &lt; {/* “less than” (küçüktür) işaretini (<) temsil eder. */}
        </button>
        <h2 className="text-3xl font-semibold">
          {format(currentDate, 'MMMM yyyy', { locale: tr })}
        </h2>
        <button
          onClick={handleNextMonth}
          className="text-2xl font-bold text-gray-700 hover:text-gray-900"
        >
          &gt; {/*“greater than” (büyüktür) işaretini (>) temsil eder.*/}
        </button>
      </div>

      <div className="grid grid-cols-7 gap-x-2 gap-y-3 md:gap-4 mb-4 text-center font-semibold">
        {['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'].map((day) => (
          <div key={day}>{day}</div>
        ))}
        {renderCalendarDays()}
      </div>

      <div className="text-center">
        <button
          className="bg-yellow-500 text-white mx-8 mb-4 md:mb-0 px-4 py-3 rounded-2xl hover:bg-yellow-600 mt-1"
          onClick={() => setRefreshKey((prev) => prev + 1)}
        >
          Kapatılan Randevular Görünmüyor mu? Tıkla Güncelle
        </button>
      </div>

      {isModalOpen && (
        <div className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-3xl shadow-lg max-w-sm md:max-w-xl w-full">
            <h2 className="text-lg font-semibold mb-4 text-center">
              {format(selectedDate, 'PPPP', { locale: tr })}
            </h2>
            <div className="mb-7">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={selectFullDay}
                  onChange={handleFullDayChange}
                  className="w-7 h-7 rounded-full"
                />
                <span className="ml-2 font-semibold">Tüm Günü Kapat</span>
              </label>
            </div>
            {/* Tüm Gün seçili değilse Randevu Saatlerini modal'da listele */}
            {!selectFullDay && renderTimeSlots()}
            <div className="mt-6 flex justify-between space-x-2">
              <button
                className="bg-gray-500 text-white px-4 py-2 md:py-3 rounded-xl"
                onClick={handleCloseModal}
              >
                İptal
              </button>

              <button
                className="bg-green-500 text-white px-4 py-2 md:py-3 rounded-xl"
                onClick={handleOpenAppointments}
              >
                Randevuları Aç
              </button>

              {/* <button
                className={`bg-green-500 text-white px-4 py-2 rounded-xl ${
                  selectFullDay ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                onClick={handleOpenAppointments}
                disabled={selectFullDay}
              >
                Randevuları Aç
              </button> */}

              <button
                className="bg-red-500 text-white px-4 py-2 md:py-3 rounded-xl"
                onClick={handleCloseAppointments}
              >
                Randevuları Kapat
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EmployeeCalendar
