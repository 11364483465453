import {
  ADMIN_GET_APPOINTMENTS_REQUEST,
  ADMIN_GET_APPOINTMENTS_SUCCESS,
  ADMIN_GET_APPOINTMENTS_FAILURE,
  ADMIN_CANCEL_APPOINTMENT_REQUEST,
  ADMIN_CANCEL_APPOINTMENT_SUCCESS,
  ADMIN_CANCEL_APPOINTMENT_FAILURE,
  ADMIN_DELETE_APPOINTMENT_REQUEST,
  ADMIN_DELETE_APPOINTMENT_SUCCESS,
  ADMIN_DELETE_APPOINTMENT_FAILURE,
  ADMIN_GET_CUSTOMERS_REQUEST,
  ADMIN_GET_CUSTOMERS_SUCCESS,
  ADMIN_GET_CUSTOMERS_FAILURE,
  ADMIN_UPDATE_CUSTOMER_REQUEST,
  ADMIN_UPDATE_CUSTOMER_SUCCESS,
  ADMIN_UPDATE_CUSTOMER_FAILURE,
  ADMIN_DELETE_CUSTOMER_REQUEST,
  ADMIN_DELETE_CUSTOMER_SUCCESS,
  ADMIN_DELETE_CUSTOMER_FAILURE,
  ADMIN_GET_EMPLOYEES_REQUEST,
  ADMIN_GET_EMPLOYEES_SUCCESS,
  ADMIN_GET_EMPLOYEES_FAILURE,
  ADMIN_ADD_EMPLOYEE_REQUEST,
  ADMIN_ADD_EMPLOYEE_SUCCESS,
  ADMIN_ADD_EMPLOYEE_FAILURE,
  ADMIN_UPDATE_EMPLOYEE_REQUEST,
  ADMIN_UPDATE_EMPLOYEE_SUCCESS,
  ADMIN_UPDATE_EMPLOYEE_FAILURE,
  ADMIN_DELETE_EMPLOYEE_REQUEST,
  ADMIN_DELETE_EMPLOYEE_SUCCESS,
  ADMIN_DELETE_EMPLOYEE_FAILURE,
  ADMIN_GET_SERVICES_REQUEST,
  ADMIN_GET_SERVICES_SUCCESS,
  ADMIN_GET_SERVICES_FAILURE,
  ADMIN_ADD_SERVICE_REQUEST,
  ADMIN_ADD_SERVICE_SUCCESS,
  ADMIN_ADD_SERVICE_FAILURE,
  ADMIN_UPDATE_SERVICE_REQUEST,
  ADMIN_UPDATE_SERVICE_SUCCESS,
  ADMIN_UPDATE_SERVICE_FAILURE,
  ADMIN_DELETE_SERVICE_REQUEST,
  ADMIN_DELETE_SERVICE_SUCCESS,
  ADMIN_DELETE_SERVICE_FAILURE,
  REMOVE_MESSAGE
} from '../types/adminTypes'

const initialState = {
  appointments: [],
  customers: [],
  employees: [],
  services: [],
  loading: false,
  error: '',
  message: ''
}

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_GET_APPOINTMENTS_REQUEST:
    case ADMIN_CANCEL_APPOINTMENT_REQUEST:
    case ADMIN_DELETE_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: []
      }
    case ADMIN_GET_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: action.payload
      }
    case ADMIN_CANCEL_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: state.appointments.map((appointment) =>
          appointment._id === action.payload
            ? { ...appointment, status: 'Cancelled' }
            : appointment
        )
      }
    case ADMIN_DELETE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: state.appointments.filter(
          (appointment) => appointment._id !== action.payload
        )
      }
    case ADMIN_GET_APPOINTMENTS_FAILURE:
    case ADMIN_CANCEL_APPOINTMENT_FAILURE:
    case ADMIN_DELETE_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case ADMIN_GET_CUSTOMERS_REQUEST:
    case ADMIN_UPDATE_CUSTOMER_REQUEST:
    case ADMIN_DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        error: []
      }
    case ADMIN_GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.payload
      }
    case ADMIN_UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: state.customers.map((customer) =>
          customer._id === action.payload._id ? action.payload : customer
        )
      }
    case ADMIN_DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: state.customers.filter(
          (customer) => customer._id !== action.payload
        )
      }
    case ADMIN_GET_CUSTOMERS_FAILURE:
    case ADMIN_UPDATE_CUSTOMER_FAILURE:
    case ADMIN_DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case ADMIN_GET_EMPLOYEES_REQUEST:
    case ADMIN_ADD_EMPLOYEE_REQUEST:
    case ADMIN_UPDATE_EMPLOYEE_REQUEST:
    case ADMIN_DELETE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        error: [],
        message: ''
      }
    case ADMIN_GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: action.payload
      }
    case ADMIN_ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: [...state.employees, action.payload],
        message: action.payload.message
      }
    case ADMIN_UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: state.employees.map((employee) =>
          employee._id === action.payload._id ? action.payload : employee
        ),
        message: action.payload.message
      }
    case ADMIN_DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: state.employees.filter(
          (employee) => employee._id !== action.payload
        ),
        message: action.payload.message
      }
    case ADMIN_GET_EMPLOYEES_FAILURE:
    case ADMIN_ADD_EMPLOYEE_FAILURE:
    case ADMIN_UPDATE_EMPLOYEE_FAILURE:
    case ADMIN_DELETE_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload
      }

    case ADMIN_GET_SERVICES_REQUEST:
    case ADMIN_ADD_SERVICE_REQUEST:
    case ADMIN_UPDATE_SERVICE_REQUEST:
    case ADMIN_DELETE_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: [],
        message: ''
      }

    case ADMIN_GET_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.payload
      }

    case ADMIN_ADD_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: [...state.services, action.payload.newService],
        message: action.payload.message
      }

    case ADMIN_UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: state.services.map((service) =>
          service._id === action.payload.updatedService._id
            ? action.payload.updatedService
            : service
        ),
        message: action.payload.message
      }

    case ADMIN_DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: state.services.filter(
          (service) => service._id !== action.payload.serviceId
        ),
        message: action.payload.message
      }

    case ADMIN_GET_SERVICES_FAILURE:
    case ADMIN_ADD_SERVICE_FAILURE:
    case ADMIN_UPDATE_SERVICE_FAILURE:
    case ADMIN_DELETE_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload
      }

    case REMOVE_MESSAGE:
      return {
        ...state,
        message: ''
      }

    default:
      return state
  }
}

export default adminReducer
