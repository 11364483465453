import {
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_REQUEST,
  REMOVE_MESSAGE
} from '../types/employeeTypes'

const initialState = {
  employees: [],
  loading: false,
  error: null,
  message: ''
}

export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
        message: ''
      }

    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.payload,
        loading: false
      }
    case GET_EMPLOYEES_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload
      }
    case REMOVE_MESSAGE:
      return {
        ...state,
        message: ''
      }
    default:
      return state
  }
}

export default employeeReducer
