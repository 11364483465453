import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loginUser, forgotPassword } from '../actions/authActions'
import { REMOVE_MESSAGE } from '../types/authTypes'
import toast from 'react-hot-toast'

import { InputMask } from '@react-input/mask'

const LoginPage = () => {
  const [phone, setPhone] = useState('(05')
  const [password, setPassword] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()
  const {
    loading,
    message = [],
    isAuthenticated = [],
    user = []
  } = useSelector((state) => state.authReducer)

  const handleSubmit = (e) => {
    e.preventDefault()

    let trimmedPhone = phone.trim().replace(/\D/g, '')
    let trimmedPassword = password.trim()

    if (trimmedPhone.length !== 11) {
      return toast.error('Telefon numarası tam 11 haneli olmalıdır.')
    }

    if (!trimmedPhone || !trimmedPassword) {
      return toast.error('Telefon ve şifre girilmelidir.')
    }
    dispatch(loginUser({ phone: trimmedPhone, password: trimmedPassword }))
  }

  useEffect(() => {
    const messagesArray = Array.isArray(message) ? message : [message]
    if (message.length > 0) {
      messagesArray.map((msg) => toast(msg, { icon: 'ℹ️' }))
    }
    dispatch({ type: REMOVE_MESSAGE })
  }, [dispatch, message])

  const navigate = useNavigate()
  useEffect(() => {
    if (isAuthenticated) {
      if (user.role === 'Customer') {
        navigate('/customer-dashboard')
      } else if (user.role === 'Employee') {
        navigate('/employee-dashboard')
      } else if (user.role === 'Admin') {
        navigate('/admin-dashboard')
      }
    }
  }, [isAuthenticated, user, navigate])

  const handleChangePhone = (e) => {
    const newValue = e.target.value
    if (newValue.startsWith('(05')) {
      setPhone(newValue)
    } else {
      setPhone('(05')
    }
  }

  const handleForgotPassword = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setEmail('')
  }

  const handleSendResetLink = () => {
    if (!email) {
      return toast.error('E-posta adresi girilmelidir.')
    }
    dispatch(forgotPassword(email))
    setIsModalOpen(false)
  }

  return (
    <div className="flex items-center bg-zinc-800 p-6 md:p-20 lg:min-h-[calc(100vh-200px)]">
      <div className="w-full max-w-sm mx-auto overflow-hidden bg-zinc-800 rounded-lg shadow-md outline outline-1 outline-charcoal">
        <div className="px-8 py-10">
          <h3 className="lg:mb-8 text-2xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-cyan-300">
            Hoş Geldiniz
          </h3>

          <form className="mt-6" onSubmit={handleSubmit}>
            <div className="mt-4">
              <InputMask
                mask="(05__) ___-__-__"
                replacement={{ _: /\d/ }}
                value={phone}
                onChange={handleChangePhone}
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                placeholder="(05__) ___-__-__"
              />
            </div>

            <div className="mt-4">
              <input
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg placeholder-gray-300 focus:ring-teal-500 focus:border-teal-500"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Şifre"
              />

              <div className="flex justify-end mt-2">
                <span
                  className="text-sm text-teal-500 cursor-pointer hover:text-teal-400"
                  onClick={handleForgotPassword}
                >
                  Şifremi Unuttum
                </span>
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="block w-full px-4 py-2 mt-6 text-white font-semibold tracking-wide rounded-lg bg-gradient-to-r from-teal-500 hover:from-teal-300"
            >
              {loading ? 'Giriş Yapılıyor...' : 'Giriş Yap'}
            </button>
          </form>
        </div>
        <Link
          className="flex items-center justify-center py-4 text-center bg-orange-300 hover:bg-orange-200"
          to="/register"
        >
          <span className="text-sm text-zinc-800 font-medium">
            Henüz hesabınız yok mu? Hemen Tıkla!
          </span>
        </Link>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-zinc-800 p-6 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-2xl font-bold text-center mb-4 text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-cyan-300">
              Şifreni mi Unuttun?
            </h2>
            <p className="text-center font-semibold mb-6 text-neutral-200">
              İnsanlık hali 😌️ Hemen şifreni yenilemek için E-Posta adresini
              girebilirsin.
            </p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-Posta Adresi"
              className="block w-full px-4 py-2 mb-4 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
            />
            <div className="flex justify-end font-semibold">
              <button
                onClick={handleCloseModal}
                className="px-4 py-2 mr-2 text-neutral-100 bg-gray-700 rounded-lg hover:bg-gray-600"
              >
                İptal
              </button>
              <button
                onClick={handleSendResetLink}
                className="px-4 py-2 text-neutral-100 bg-teal-600 rounded-lg hover:bg-teal-500"
              >
                Gönder
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LoginPage
